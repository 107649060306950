import React from "react";

export const recordButtonStyle = {
  width: "200px",
  height: "200px",
  background: "red", // "#a84909",
  borderRadius: "50%",
  paddingTop: 50,
  paddingBottom: 40,
  marginLeft: "39%",
  marginBottom: 20,
  fontSize: "42pt",
  fontWeight: 500,
  letterSpacing: '1px',
  color: "AliceBlue"
},
  bigHeaderStyle = {
    width: "100%",
    height: "80px",
    // background: "#00B3DE",
    background: "#ddd",
    borderRadius: "40px",
    paddingTop: 17,
    paddingBottom: 37,
    // marginLeft: "3%",
    marginBottom: 20,
    fontSize: "36pt",
    minHeight: 105,
    fontWeight: 500,
    letterSpacing: '1px',
    color: "AliceBlue"
  },
  apiServer = "//" + (window.document.location.hostname === "localhost" ?
    "localhost:8484" : window.document.location.host) + "/api/",
  apiPath = path => apiServer + path,
  jsonHeadersPostForm = {
    method: "POST",
    crossDomain: true,
    headers: { "Content-Type": "application/json" }
  },
  jsonPostAuthHeaders = userId => {
    return {
      ...jsonHeadersPostForm,
      headers: {
        ...jsonHeadersPostForm.headers,
        "Authorization": "Bearer " + userId,
        "X-Authorization": "Bearer " + userId
      }
    };
  },
  loadHelloData = async (userId, callback) => {
    // console.info(`Authing`, userId);
    const resp = await fetch(apiPath(`status`),
      jsonPostAuthHeaders(userId)),
      data = resp ? (await resp.json()) : {};
    callback(data);
  },
  smallWhoAmI = userState => userState ? <div style={{ position: "relative" }}>
    <div className="has-text-left is-size-7 is-family-monospace"
      style={{ position: "absolute", bottom: 0, opacity: 0.13 }}
    >{userState.participantCode} {userState.headers ?
      `${userState.headers["cloudfront-viewer-city"] || ""} ${userState.headers["cloudfront-viewer-country-region-name"] || ""}` :
      null}
    </div></div> : null;
