import React from "react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const NoPageHere = _props => {
  return <div>
    <div className="columns">
      <div className="column is-6 is-offset-3 has-text-centered" style={{ minHeight: 400 }}>
        <h3 className="title is-3">Sorry, no page here.</h3>
        <pre style={{ fontSize: '24pt' }}>
          {window.document.location.pathname}
        </pre>
        <br />
        <h3 className="title is-3">Address Unknown.</h3>
        <a style={{ minWidth: 288 }} className="button is-primary"
          onClick={() => { window.history.back(); return false }}
          href="javascript:history.back()">
            <FontAwesomeIcon icon={faArrowLeft} 
            style={{marginRight: 9}}
            /> Click to go back</a>
        <br /><br />
        <a style={{ minWidth: 288 }} className="button is-danger" href="/">
          Click to start again</a>
        <br /><br />
        <a style={{ minWidth: 288 }} className="button is-warning"
          title={`Email support@speakunique.co.uk`}
          href="mailto:support@speakunique.co.uk">Click to contact us</a>
      </div>
    </div>
  </div>;
};
