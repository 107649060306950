import React from "react";

const gigantify = content => <h4 className="title is-4" style={{ fontWeight: 500 }}>{content}</h4>;

export const Task1Tips = _props => {

  return <div className="columns">
    <div className="column is-10 is-offset-1">
      <div className="bigBoxes" style={{ padding: '44px' }} >
        <h2 className="title is-2">About this task</h2>
        {`In this task we want you to read a short story.
          For each sentence, please press Record, read the sentence out loud and then press Stop.
          The volume bars on the side will move to show the website is recording your voice. You might get a message if the volume is too quiet or too loud.
          If you get stuck on any of the words you can click on the word to hear someone reading it out loud. 
          Please only record you speaking. If someone else is helping you, wait until they have stopped speaking before you press record. 
          Don’t worry if you make a mistake – you can just rerecord the sentence.
          If you are using a microphone, please plug it into your computer now.
          `.split(/\n/).filter(f => f.match(/\S/)).map(gigantify)}

        <div className="has-text-centered">
          <button
            onClick={() => window.document.location.assign("/task-1-1")}
          >Begin</button>
        </div>
      </div>
    </div>
  </div>;
};

export const Task2Tips = _props => {
  return <div className="columns">
    <div className="column is-10 is-offset-1">
      <div className="bigBoxes" style={{ padding: '44px' }} >
        <h2 className="title is-2">About this task</h2>
        {`In this task we want you to describe a picture.
          For each picture, please press record and then tell us as much as you can about the picture. Once you have finished describing the picture please press stop and move onto the next picture. 
          The volume bars on the side will move to show the website is recording your voice. You might get a message if the volume is too quiet or too loud.
          If you are not sure what to say, there is a box on the page which contains some suggestions for things to look at and describe.  
          Please only record you speaking. If someone else is helping you, wait until they have stopped speaking before you press record. 
          We want you to say as many different things as you can so please speak in full sentences and give us much detail as possible!
          If you are using a microphone, please plug it into your computer now.
          `.split(/\n/).filter(f => f.match(/\S/)).map(gigantify)}

        <div className="has-text-centered">
          <button
            onClick={() => window.document.location.assign("task-2-1")}
          >Begin</button>
        </div>
      </div>
    </div>
  </div>;
};

export const Task3Tips = _props => {
  return <div className="columns">
    <div className="column is-10 is-offset-1">
      <div className="bigBoxes" style={{ padding: '44px' }} >
        <h2 className="title is-2">About this task</h2>
        {`In this task we want you to answer some questions.
          On the next page you can pick five different questions you would like to answer.
          For each question, please press record and then answer the question in full sentences. Once you have finished answering the question, please press stop and move onto the next question.
          The volume bars on the side will move to show the website is recording your voice. You might get a message if the volume is too quiet or too loud.
          If you are not sure what to say, there is a box on the page which contains some suggestions for things to include in your answer.  
          Please only record you speaking. If someone else is helping you, wait until they have stopped speaking before you press record. 
          We want you to say as many different things as you can so please speak in full sentences and give us much detail as possible!
          If you are using a microphone, please plug it into your computer now.
          `.split(/\n/).filter(f => f.match(/\S/)).map(gigantify)}

        <div className="has-text-centered">
          <button
            onClick={() => window.document.location.assign("task-3-1")}
          >Begin</button>
        </div>
      </div>
    </div>
  </div>;
};
