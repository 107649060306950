import React, { _useState, _useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

export const Step1Contents = _props => {
  const boxStyle = {
    border: '1px solid #999', padding: '3px 64px 3px',
    marginBottom: '9px'
  },
    linkStyle = { color: 'black', fontWeight: 500 },
    externalLink = <FontAwesomeIcon icon={faExternalLinkAlt} size="2x" style={{ paddingLeft: "12px" }} />;

    const userId = localStorage.getItem("i");

  return <div className="columns">
    <div className="column is-10 is-offset-1">
      <h4 className="title is-4" style={{ fontWeight: 400 }}>
        Thank you for your interest in taking part in the Junior Voice Project<br /><br />
        You should have been provided with an information pack containing all the information required to sign up to the Junior Voice Project.<br /><br />
        The same information can also be found here.
      </h4>
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <div className="is-centered" style={boxStyle}>
            <a style={linkStyle}
              target="_blank" rel="no-opener no-referrer"
              href={process.env.PUBLIC_URL + "/Registration instructions.pdf"}>
              Registration Instructions
            </a>
          </div>
          <div className="is-centered" style={boxStyle}>
            <a style={linkStyle}
              target="_blank" rel="no-opener no-referrer"
              href={process.env.PUBLIC_URL + "/Participant Information Sheet .pdf"}>
              Participant Information Sheet
            </a>
          </div>
          <div className="is-centered" style={boxStyle}>
            <a style={linkStyle}
              target="_blank" rel="no-opener no-referrer"
              href={process.env.PUBLIC_URL + "/Parent Guardian Consent Form.pdf"}>
              Parent/Guardian Information Sheet
            </a>
          </div>
          <div className="is-centered" style={boxStyle}>
            <a style={linkStyle}
              target="_blank" rel="no-opener no-referrer"
              href={process.env.PUBLIC_URL + "/Parent Information Sheet .pdf"}>
              Parent Consent Form
            </a>
          </div>
          <div className="is-centered" style={boxStyle}>
            <a style={linkStyle}
              target="_blank" rel="no-opener no-referrer"
              href={process.env.PUBLIC_URL + "/Participant Assent Form.pdf"}>
              Participant Assent Form
            </a>
          </div>
          <div className="is-centered" style={boxStyle}>
            <a style={linkStyle}
              target="_blank" rel="no-opener no-referrer"
              href={process.env.PUBLIC_URL + "/GDPR Information Sheet.pdf"}>
              GDPR Information Sheet
            </a>
          </div>
        </div>
      </div>
      <div className="has-text-centered">
        { !!userId ?
        <a href="/start">
        <button style={{ margin: 20 }} >Resume</button>
      </a> : 
        <a href="2">
          <button style={{ margin: 20 }} >Get Started</button>
        </a>}
      </div>
    </div>
  </div>;
};
