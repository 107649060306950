import React, { useState } from "react";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { apiPath } = require("./_Util");

export const Step2Login = _props => {
  const [code, setCode] = useState(""),
    [password, setPassword] = useState(),
    formUrl = apiPath("login"),
    formHeaders = {
      method: "POST",
      crossDomain: true,
      headers: { "Content-Type": "application/json" }
    },
    submitForm = e => {
      e.preventDefault();

      const vals = {
        code, password
      };
      fetch(formUrl, {
        ...formHeaders,
        body: JSON.stringify(vals)
      })
        .then(r => r.ok ? r.json() : {})
        .then(data => {
          if (data && data.i) localStorage.setItem("i", data.i);
          if (data && data.url) return window.document.location.assign(data.url);
          return window.alert(`Login failed, please try again`);
        });
    };

  const bigStyle = { fontSize: "23px" },
    bigInputStyle = { ...bigStyle, border: "5px solid rgb(239, 194, 82)", width: 393 },
    devColumnStyle = { border: '1px dotted grey' },
    pageColumnStyle = window.document.hostname === "localhost" ? devColumnStyle : {};

  return <div>
    <form>
      <div className="columns">
        <div className="column is-8 is-offset-2" style={pageColumnStyle}>
          <h3 className="title is-3">
            Login
          </h3>
          <br />
          <table className="table is-table is-fullwidth">
            <tbody>
              <tr style={bigStyle}>
                <td>Unique participant code <FontAwesomeIcon icon={faQuestionCircle}
                  onClick={() => {
                    const c = window.document.getElementById("upcHelp");
                    if (c.classList.contains("is-hidden")) return c.classList.remove("is-hidden");
                    c.classList.add("is-hidden");
                  }}
                />
                  <div className="is-hidden" id="upcHelp">
                    You can find your Unique Participant Code on the information letter sent home to you.
                  </div>
                </td>
                <td><input id="upcInput" name="particCode" className="regInput boxShadow"
                  placeholder="" style={bigInputStyle}
                  type="numeric" required={true} value={code} onChange={e => setCode(e.target.value)} /></td>
              </tr>
              <tr style={bigStyle}>
                <td>Password</td>
                <td><input name="chosenPassword" style={bigInputStyle}
                  size={32} maxLength={64}
                  type="text" required={true} value={password} onChange={e => setPassword(e.target.value)} /></td>
              </tr>
            </tbody>
          </table>
          <div className="has-text-right">
            <button className="spunButton"
              onClick={submitForm}
              style={{ margin: 20 }}
            >Login</button>
          </div>
        </div>
      </div>
    </form>
    <div className="columns">
      <div className="column is-offset-1">
        If you do not have an account please <a href={'/2'}
          style={{ fontWeight: "bold" }}
          className="orange hinted">register here</a>.
      </div>
      <div className="column is-narrow has-text-right" style={{ paddingRight: 40, fontWeight: 600 }}>
        {/* Registration: Step 1/3 */}
      </div>
    </div>
  </div>;
};
