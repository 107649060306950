import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Welcome } from "./handlers/Welcome";
import { Step1Contents } from "./handlers/Step1Contents";
import { Step2Register } from "./handlers/Step2Register";
import { Step2Login } from "./handlers/Step2Login";
import { Step3ParentalConsent, Step3UserConsent } from "./handlers/Step3Consent";
import { RegisteredWelcome, AllDone } from "./handlers/Registered";
import { RegisteredWelcome4Boxes } from "./handlers/Tasks";
import { Task1Tips, Task2Tips, Task3Tips } from "./handlers/TaskTips";
import { MicrophonePosition } from "./handlers/MicrophonePosition";
import { RecordEnabledScreen } from "./handlers/TaskRecorder";
import { RecordingInstructions } from "./handlers/RecordingInstructions";
import { NoPageHere } from "./handlers/zNoPageHere";

const App = () => <Router basename={""}>
  <Switch>
    <Route exact path="/" component={Welcome} />
    <Route exact path="/1" component={Step1Contents} />
    <Route exact path="/2" component={Step2Register} />
    <Route exact path="/l" component={Step2Login} />
    <Route exact path="/3" component={Step3ParentalConsent} />
    <Route exact path="/3a" component={Step3UserConsent} />
    <Route exact path="/4" component={RegisteredWelcome} />
    <Route exact path="/start" component={RegisteredWelcome4Boxes} />
    <Route exact path="/recording-tips" component={RecordingInstructions} />
    <Route exact path="/finished" component={AllDone} />
    <Route exact path="/tips" component={MicrophonePosition} />
    <Route exact path="/start-task-1" component={Task1Tips} />
    <Route exact path="/task-1-1" component={RecordEnabledScreen} />
    <Route exact path="/task-1-2" component={RecordEnabledScreen} />
    <Route exact path="/task-1-3" component={RecordEnabledScreen} />
    <Route exact path="/task-1-4" component={RecordEnabledScreen} />
    <Route exact path="/task-1-5" component={RecordEnabledScreen} />
    <Route exact path="/start-task-2" component={Task2Tips} />
    <Route exact path="/task-2-1" component={RecordEnabledScreen} />
    <Route exact path="/task-2-2" component={RecordEnabledScreen} />
    <Route exact path="/task-2-3" component={RecordEnabledScreen} />
    <Route exact path="/task-2-4" component={RecordEnabledScreen} />
    <Route exact path="/task-2-5" component={RecordEnabledScreen} />
    <Route exact path="/start-task-3" component={Task3Tips} />
    <Route exact path="/task-3-1" component={RecordEnabledScreen} />
    <Route path="/" component={NoPageHere} />
  </Switch>
</Router>;

export default App;
