import React, { Component } from "react";
// import { bigHeaderStyle, apiServer, apiPath, loadHelloData } from "./_Util";
import { prompts } from "./_Prompts";
import Recorder from "recorder-js";
import ArrowKeysReact from "arrow-keys-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import {
  faArrowAltCircleLeft, faArrowLeft, faArrowRight, faArrowAltCircleRight,
  faCheckSquare, faCircle, faDotCircle, faMicrophone, faMicrophoneSlash,
  faQuestionCircle, faRecordVinyl, faSquare, faTrash, faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { RadioGroup, Radio } from "react-radio-group";

const { bigHeaderStyle, apiServer, apiPath, loadHelloData, smallWhoAmI } = require("./_Util");

const task1Sentences = [
  `Please call Stella`,
  `Ask her to bring these things with her from the store`,
  `Six spoons of fresh snow peas, five thick slabs of blue cheese, and maybe a snack for her brother Bob.`,
  `We also need a small plastic snake and a big toy frog for the kids.`,
  `She can scoop these things into three red bags, and we will go meet her Wednesday at the train station.`],
  task1Audio = [
    `/s001.wav`,
    `/s002.wav`,
    `/s003.wav`,
    `/s004.wav`,
    `/s005.wav`
  ].map(file => process.env.PUBLIC_URL + file),
  task2ImageSrcs = [
    'thats silly beach.jpeg', 'thats silly cafe.jpeg',
    'thats silly optician.jpeg', 'thats silly park.jpeg',
    'thats silly swimming.jpeg'],
  task2PicturePrompts = [
    ['What animals can you see?', 'What are they doing?', 'What are the children doing?',
      'What silly things can you see?', 'What do you think is going to happen?'],
    ['What are all the different people doing?', 'What foods can you see?', 'Do the foods look yummy or disgusting?',
      'Which foods would you like to eat?', 'What animals can you see and what are they doing?'],
    ['What happens when you see an optician?', 'Can you describe the people you see in the picture?',
      'Can you describe the animals you see in the picture?', 'What silly things can you see?'],
    ['What can you see in this park?', 'Look at the different people and tell us what they are doing.',
      'Look at the different animals and tell us what they are doing.', 'What silly things can you see?',
      'Why are the trees silly?', 'Why are the birds silly?'],
    [`What do you see in the water?`,
      `What do you see outside the water?`,
      `What are the people doing?`,
      `Is there anything silly?`,
      `Is there anything unusual?`]
  ],
  task2ColumnsMinHeight = 366,
  shuffleArray = array => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  },
  task3Questions = [
    [`Tell me about your favourite animal`,
      ['What does it look like?', 'What does it eat?', 'Where does it live?']
    ],
    [`What are your three favourite things, and why`,
      ['Is it a toy?', 'Is it an iPad or TV?', 'Is it books/a game?', "Why it's your favourite thing?"]
    ],
    [`Do you prefer the beach or the park?`,
      ['Why', 'What do you do there?', 'What do you play with?']
    ],
    [`If you could wake up with a superpower, what would it be?`,
      ['Maybe it would be flying, being invisible, being super strong', 'Why do you want this superpower?',
        'Is it the same power as a superhero?']
    ],
    [`Make three wishes!`],
    [`If you were a teacher, what rules would you make?`,
      ['What would you do to snack time and play time?',
        'Would you make school days longer or shorter?']
    ],
    [`What do you do when you get home from school?`,
      ['What snack do you have?', 'Do you play or watch TV?']
    ],
    [`Tell us some jokes!`],
    [`What's your favourite day of the week, and why`,
      ['What do you do on this day?', 'Is it a busy day or a quiet day?', 'Why is it your favourite?']],
    [`Tell us three foods you love, and three foods you hate!`]
  ];//                 <--- [EXIT]            |[]|________________ /^\                 running.
/////  Two 8s flee     _______________        |  |         ///     | |              is     _______
////                                  \___    |  |         ///====-|||        clock   ____/%%%%%%%\
const showTooltips = true,           /////+--+|  |         ///     |U|    The    ____/%%%%%%%%%%%%|
  visualiserHeight = 280,             ////+--_______8___8__///_____|S|          /%%%%%%%%%%%%%%%%/
  visualiserWidth = 41,                ///+--+|  |         ///     |A|    _____/%%%%%%%%%%%%%%%%|
  visualiserSegments = 10,              //+--+|__|         ///====-|||   /%%%%%%%%%%%%%%%%%%%%%/
  visualiserMiniHeight = 27,             //--______________|||%%%__***__/%%%%%%%%%%%%%%%%%%%%%|
  maximumRecordings = 5,                  ////             |||%%%*******%%%%%%%%%%%%%%%%%%%%%%%\_
  minimumSampleRate = 44100,               ///             |||%***********%%%%%%%%%%%%%%%J%%J%%%%\_
  minimumBlobSizeToUpload = 1024 * 50,      //_____________|||%%%*******%%%%%%%%%%%%%%%%%%%%%%%%%%%|
  playbackElementId = "adf1973",
  visualiserSegmentHeight = visualiserHeight / visualiserSegments,
  minimumSampleKilobytes = 100, // minimum size of server upload confirmation to be considered successful
  defaultFadeSuccessOverlayMS = 650, // milliseconds
  enableMicrophoneConfirmation = true, // !!(window.document.location.hostname.match(/localhost|latest/i)),
  serverURL = apiServer,
  submitSampleURL = serverURL + "add-sample",
  finishedPingURL = serverURL + "finished",
  pathName = window.document.location.pathname,
  numInUrl = pathName.replace(/.+-/, ""),
  taskNumInUrl = pathName.replace(/^.+-(\d)-\d+$/, "$1"),
  greenBackgroundStyle = { ...bigHeaderStyle, background: "#01DE29" },
  defaultState = {
    userState: {},
    finished: 0, // 1 for true to skip everything and say thank you
    currentTaskNumber: parseInt(taskNumInUrl),
    currentSentenceNumber: parseInt(numInUrl),
    savedOk: null,
    currentSentenceHash: null,
    percentComplete: 0.0,
    readyToRecord: false, // disable the record button until the microphone has been enabled
    currentSentences: [], // the contents we're currently reading
    currentSentence: "", // the sentence we're currently reading
    microphoneDevices: [], // !!localStorage.getItem("microphoneDevices") ?
    // JSON.parse(localStorage.getItem("microphoneDevices")) : [], // all the mics
    microphoneName: localStorage.getItem("microphoneName"), // what microphone we think we're using to record
    microphoneDeviceId: localStorage.getItem("microphoneDeviceId"), // internal id of the selected mic
    microphoneIsConfirmed: !!localStorage.getItem("microphoneIsConfirmed"), // have they explicitly confirmed the microphone they want to use
    microphoneOptionClicked: false, // have they actually clicked a selection on the microphone selection screen
    microphoneIntroScreen: !!localStorage.getItem("microphoneIntroScreen"), // have they seen the microphone intro screen, which just initiates a user action to enable the mic
    blob: null,
    waveFormImgSrc: null,
    encouragingMessage: "",
    recordingQuestionNo: null, // on task 3, which question number we're recording
    isRecording: false,
    isUploading: false,
    uploadingSince: null,
    uploadComplete: null,
    uploadSuccessful: null,
    uploadAnalysis: null,
    bytesUploaded: null,
    backgroundRecordings: null,
    backgroundNoiseCheckScreen: false,
    backgroundNoiseChecked: false,
    backgroundNoiseOk: null,
    backgroundProgressPercent: 0,
    stream: null,
    enableVisualiser: true,
    circleOrRedo: "circle",
    everythingLoaded: false,
    audioLevel: 0.0,
    showTooltips,
    task3numAnswers: 0,
    haveRecorded: false,
    task3UserSelectedQuestions: [],
    task3QuestionsAreSelected: false,
    task3WhatQuestionWeOn: 0,
    audioOptions: {
      // echoCancellation: false,
      // autoGainControl: false,
      // noiseSuppression: false,
      // channelCount: 1
      nothing: null
    }
  },
  osDetails = () => {
    let rv = {};
    const attrs = [
      "vendor", "hardwareConcurrency", "cookieEnabled", "appCodeName",
      "appName", "appVersion", "platform", "product", "userAgent",
      "language", "onLine", "doNotTrack"];
    attrs.forEach(attr => (rv[attr] = window.navigator[attr]));
    return rv;
  },
  headerBackgroundStyle = {
    ...bigHeaderStyle,
    background: "orange" //"#00B3DE",
  };

export class RecordEnabledScreen extends Component {
  constructor(...args) {
    super(...args);

    this.bindMethods = this.bindMethods.bind(this);
    this.bindMethods();

    ArrowKeysReact.config({
      left: this.previousSentence,
      right: this.nextSentence
    });

    const token = localStorage.getItem("i") || null;
    this.state = {
      ...defaultState,
      token
    };

    window.setTimeout(() => {
      loadHelloData(token, userData => {
        if (userData.consentsMissing && userData.consentsMissing.length)
          return window.document.location.assign(
            userData.consentsMissing[0] === "parent" ? "/3" : "/3a");

        this.setState({ userData })
      });
    }, 33);

    if (this.state.currentTaskNumber !== 3) return;
    window.setTimeout(async () => {

      fetch(`${serverURL}seeTask3Questions`, {
        method: "POST", headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user: token })
      }).then(r => r.ok ? r.json() : null)
        .then(list => list ? list.selectedQuestions : null)
        .then(task3UserSelectedQuestions => {
          if (!this.state) return;
          if (!task3UserSelectedQuestions) return;
          // console.info(task3UserSelectedQuestions);
          this.setState({ task3UserSelectedQuestions }); //
        });
    }, 143);
  }
  bindMethods() {
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.play = this.play.bind(this);
    this.playExample = this.playExample.bind(this);
    this.setupMicrophone = this.setupMicrophone.bind(this);
    this.activateMicrophone = this.activateMicrophone.bind(this);
    this.toggle = this.toggle.bind(this);
    this.nextSentence = this.nextSentence.bind(this);
    this.previousSentence = this.previousSentence.bind(this);
    this.drawVisualiser = this.drawVisualiser.bind(this);
    this._drawVisualiser = this._drawVisualiser.bind(this);
    this.blankVisualiser = this.blankVisualiser.bind(this);
    this.uploadAudioSample = this.uploadAudioSample.bind(this);
    this.backgroundNoiseCheckScreen = this
      .backgroundNoiseCheckScreen.bind(this);
    this.finishedRecording = this.finishedRecording.bind(this);
    this.mainScreen = this.mainScreen.bind(this);
    this.microphoneIntroScreen = this.microphoneIntroScreen.bind(this);
    this.microphoneConfirmation = this.microphoneConfirmation.bind(this);
    this.microphoneSelectorPopup = this.microphoneSelectorPopup.bind(this);
    this.microphoneSelectorRadio = this.microphoneSelectorRadio.bind(this);
    this.audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
  }
  render() {
    if (!this.state) return null;
    const {
      isRecording,
      finished,
      token,
      everythingLoaded,
      straightToRecord,
      backgroundNoiseCheckScreen,
      microphoneIsConfirmed,
      microphoneIntroScreen,
      useLoFiRecorder,
      task3QuestionsAreSelected,
      task3WhatQuestionWeOn
    } = this.state;

    // must be logged-in to be here
    if (!(token || localStorage.getItem("i"))) return window.document.location.assign("/");

    // if (window.document.location.hostname === "localhost")
    //   return this.finishedRecording(token); // dev 17/1/21

    if (backgroundNoiseCheckScreen) return this.backgroundNoiseCheckScreen(isRecording);
    if (finished) return this.finishedRecording(token);
    if (straightToRecord || !everythingLoaded)
      return <div className="container has-text-centered orange is-italic">
        <br /><br />Loading...</div>;

    if (enableMicrophoneConfirmation && !microphoneIntroScreen && useLoFiRecorder !== "Yes")
      return this.microphoneIntroScreen();

    if (enableMicrophoneConfirmation && !microphoneIsConfirmed && useLoFiRecorder !== "Yes")
      return this.microphoneConfirmation();

    return this.mainScreen();
  }
  activateMicrophone(opts, cb, failCb = null) {
    navigator.mediaDevices
      .getUserMedia(opts).then(cb).catch(failCb);
  }
  setupMicrophone() {
    const { microphoneDeviceId, audioOptions } = this.state;

    if (typeof navigator.mediaDevices === "undefined")
      return window.alert("Sorry, cannot continue: We are unable to capture audio.");

    const mediaOptions = {
      audio: {
        ...audioOptions,
        deviceId: microphoneDeviceId || null
      },
      video: false
    };
    this.activateMicrophone(mediaOptions, stream => {
      navigator.mediaDevices.enumerateDevices({ audio: true })
        .then(devices => {
          const currentDevice = microphoneDeviceId ?
            (devices.filter(d => d.deviceId === microphoneDeviceId)[0] || devices[0]) :
            devices[0],
            newStatePartial = {
              readyToRecord: true,
              microphoneDevices: devices.filter(d => d.kind === "audioinput"),
              microphoneName: currentDevice.label,
              microphoneDeviceId: currentDevice.deviceId,
            };
          localStorage.setItem("microphoneName", newStatePartial.microphoneName);
          localStorage.setItem("microphoneDevices", JSON.stringify(newStatePartial.microphonDevices));
          localStorage.setItem("microphoneDeviceId", newStatePartial.microphoneDeviceId);

          this.setState({ ...newStatePartial, stream });
          window.setTimeout(() => {
            this.recorder = new Recorder(this.audioContext, {
              ...mediaOptions.audio,
              onAnalysed: stream => {
                const { microphoneIsConfirmed, microphoneDeviceId, microphoneOptionClicked, enableVisualiser, isRecording } = this.state,
                  trigger = ((!microphoneIsConfirmed && microphoneDeviceId && microphoneOptionClicked) || (enableVisualiser && isRecording)),
                  highestNumRelative = trigger ? (Math.max(...stream.data) / 255) * 100 * (visualiserSegments / 10) : 0;

                if (trigger && highestNumRelative > 0) this.drawVisualiser(highestNumRelative, isRecording ? [] : ["micCanvas"]);
              }
            });
            if (!stream) return;
            try {
              (this.recorder || super.recorder).init(stream);
            } catch (e) { console.error(e) }
          }, 143); // after state change
        })
        .catch(console.error);
    }, this.notGotStream);
  }
  componentDidMount() {
    if (
      window.document.location.protocol !== "https:" &&
      window.document.location.hostname !== "localhost" &&
      window.document.location.hostname !== "jvb.speakunique.co.uk" && // test server, no https yet
      !window.document.location.hostname.match(/^(?:junior|jvbn(?:ode)?)\.speakunique\.co\.uk$/) // uni test server, no https yet
    ) window.document.location.protocol = "https:";

    if (!this.state) return null;

    // let headers = new Headers();
    // const { token } = this.state;
    this.setupMicrophone();

    const doSetup = async (sentencesData = {}) => {
      const newState = {
        currentSentences: task1Sentences || sentencesData.sentences || [],
        currentSentenceNumber: this.state.currentSentenceNumber || 1,
        scriptName: "unknown",
        // useLoFiRecorder, // this is deprecated, avoid repeated microphone confirmations
        lastEnvSentence: "",
        lengthEnvSentences: 0,
        percentComplete: 0,
        audioOptions: defaultState.audioOptions,
        everythingLoaded: true
      };
      this.setState({
        ...newState,
        currentSentence: newState.currentSentences[newState.currentSentenceNumber - 1]
      });
    };
    doSetup();
  }
  drawVisualiser(level, canvases = []) {
    if (!canvases.length) {
      this._drawVisualiser(level, "myCanvas");
      this._drawVisualiser(level, "myCanvas2");
      return;
    }
    for (let j in canvases) this._drawVisualiser(level, canvases[j]);
  }
  _drawVisualiser(level, canvasId = "myCanvas") {
    const canvas = document.getElementById(canvasId),
      ctx = canvas ? canvas.getContext("2d") : null,
      colours = [
        "red",
        "yellow",
        "yellow",
        "lime",
        "lime",
        "green",
        "green",
        "green",
        "yellow",
        "red"
      ];

    if (!ctx) return;

    for (
      let segmentNumber = 1;
      segmentNumber <= visualiserSegments;
      ++segmentNumber
    ) {
      const colour = level > 0
        ? segmentNumber * visualiserSegments < level
          ? colours[segmentNumber - 1]
          : "white"
        : "white";
      this._drawSegment(ctx, segmentNumber, colour);
    }
  }
  _drawSegment(ctx, segmentNumber, colour = "white") {
    if (typeof ctx.beginPath === "undefined") return;

    const startingY =
      segmentNumber === 1
        ? visualiserHeight
        : visualiserHeight - (segmentNumber - 1) * visualiserSegmentHeight;

    ctx.beginPath();
    ctx.fillStyle = colour;
    ctx.fillRect(0, startingY, visualiserWidth, visualiserSegmentHeight * -1);
  }
  blankVisualiser() {
    this.drawVisualiser(0.0);
  }
  start() {
    // console.info(`Starting`);
    this.recorder.start().then(() => {
      const get = document.getElementById.bind(document),
        f = get("currentSentenceH"),
        o = get("underOverlay");

      if (f) f.classList.add("isRecordingTxt");
      if (o) o.classList.add("underOverlayRecording");

      this.setState({
        isRecording: true,
        startedAt: new Date(),
        uploadSuccessful: null,
        currentSentenceHash: null, // prevent playback of anything previous
      });
    });
  }
  stop(callback = null) {
    const get = document.getElementById.bind(document),
      audioElement = get(playbackElementId);

    if (audioElement) audioElement.remove(); // stop playback
    if (!this.state.isRecording) return;

    const f = get("currentSentenceH"),
      o = get("underOverlay"),
      { currentSentenceNumber,
        currentSentence,
        backgroundNoiseCheckScreen,
        token
      } = this.state,
      blobDetails = {
        currentSentenceNumber,
        currentSentence,
        backgroundNoiseCheckScreen,
        token,
        submitURL: submitSampleURL
      };

    if (f) f.classList.remove("isRecordingTxt");
    if (o) o.classList.remove("underOverlayRecording");

    window.setTimeout(() => {
      this.recorder.stop().then(({ blob }) => {
        this.blankVisualiser();
        this.uploadAudioSample(blob, blobDetails, callback);
        this.setState({
          isRecording: false,
          stoppedAt: new Date()
        });
      });
    }, 143);
  }
  uploadAudioSample(blob, blobDetails, callback = null) {
    if (blob.size === 44) {
      if (
        window.confirm(
          ["Sorry, no sound is being recorded.",
            "The page will now re-load to see if that resolves the issue.",
            "If this keeps happening please contact us for assistance."].join("\n\n")
        )
      ) {
        window.scrollTo(0, 0);
        window.document.location.reload();
      }
      return;
    }
    if (blob.size < minimumBlobSizeToUpload) return;

    const loadingMessage = window.document.getElementById("loadingMessage");
    if (loadingMessage && loadingMessage.innerHTML) loadingMessage.innerHTML = 'Uploading...';

    const {
      submitURL,
      currentSentenceNumber,
      currentSentence
    } = blobDetails,
      { encouragingMessage,
        microphoneName,
        microphoneDevices,
        currentTaskNumber,
        token
      } = this.state,
      fd = new FormData();

    if (!token) {
      if (window.confirm(`Please login to Junior Voice Project to record your voice.`))
        window.document.location.assign(`/l`); //
      return;
    }

    fd.append("audioData", blob);

    switch (currentTaskNumber) {
      case 1:
        fd.append("textRead", currentSentence);
        fd.append("sentenceNumber", currentSentenceNumber.toString());
        break;
      case 2:
        fd.append("textRead", `Task 2 pic ${currentSentenceNumber} (${task2ImageSrcs[
          currentSentenceNumber - 1
        ].replace(/^thats silly |\.jpeg$/g, "")})`);
        break;
      case 3:
        fd.append("textRead", `Task 3 Question ${this.state.task3ScreenQuestionNumber
          }: ${this.state.task3UserSelectedQuestions[
            this.state.task3ScreenQuestionNumber - 1
          ].text}`);
        break;
    }

    this.setState({
      haveRecorded: true,
      uploadComplete: false,
      bytesUploaded: 0,
      uploadingSince: new Date()
    });
    fd.append("microphoneDetails", microphoneName ? microphoneName.replace(/^Default - /, "") : '?');
    fd.append("platformDetails", JSON.stringify({
      ...osDetails(),
      microphoneDevices,
      SpunRecorderPath: window.document.location.pathname
    }));
    fetch(submitURL, {
      method: "POST",
      body: fd,
      headers: { "Authorization": `Bearer ${token}` }
    })
      .then(response => response.ok ? response.json() : null)
      .then(allTheData => {
        if (!(allTheData)) {
          window.alert(`Sorry, a server error occurred. Please try again or contact us for assistance.`);
          this.setState({
            uploadComplete: true,
            uploadSuccessful: false,
            isUploading: false
          });
          return;
        }
        const encouragingMessages = [
          "You're doing great!",
          "Keep it up!",
          "Great progress!"
        ],
          {
            bytes,
            status,
            savedOk,
            clipped,
            sampleRate,
            hash
          } = allTheData ? allTheData : {},
          fixedMessages = {
            // the str prefix is just to ensure these keys are strings
            str100: `You've completed 100 sentences! Well done! Now is a good time for a short break.`,
            str250: "Nearly finished! Great work!"
          },
          thisEncouragingMessage =
            encouragingMessage ||
            (typeof fixedMessages[`str${currentSentenceNumber}`] !== "undefined"
              ? fixedMessages[`str${currentSentenceNumber}`]
              : encouragingMessage === "" && currentSentenceNumber % 20 === 0
                ? encouragingMessages[
                Math.floor(Math.random() * encouragingMessages.length)
                ]
                : ""),
          uploadSuccessful = savedOk && bytes > 1024 * minimumSampleKilobytes,
          sampleRateOk = parseInt(sampleRate) >= minimumSampleRate,
          uploadAllGood = (sampleRateOk && clipped === "No" && status.match(/^(?:GREEN|NEW)$/)),
          stateChange = {
            isUploading: false,
            savedOk,
            uploadSuccessful,
            uploadAllGood,
            bytesUploaded: bytes,
            encouragingMessage: thisEncouragingMessage,
            currentSentenceHash: hash,
            uploadAnalysis: (!sampleRateOk) ? "sampleRate" : (clipped === "Yes" ? "clipped" : status),
            uploadComplete: true,
          };

        const loadingMessage = window.document.getElementById("loadingMessage");
        if (loadingMessage && loadingMessage.innerHTML) loadingMessage.innerHTML = '';

        // console.info(`Setting state`, stateChange, `from data`, allTheData);
        this.setState(stateChange);
        if (!!callback && typeof callback === "function") callback();
      });
  }
  nextSentence(newState = {}, force = false) {
    const {
      isRecording,
      currentSentenceNumber,
      backgroundRecordings,
      isUploading,
    } = this.state;

    if (isUploading && !force) return;
    if (isRecording) this.stop();

    const enableBackgroundRecordings = false; // temporary
    if (
      typeof newState["backgroundNoiseCheckScreen"] === "undefined" && // allow override via the 'skip' button
      enableBackgroundRecordings &&
      typeof backgroundRecordings === "number"
    ) {
      const backgroundNoiseCheck =
        (currentSentenceNumber > 3 && backgroundRecordings < 1) ||
        (currentSentenceNumber > 30 && backgroundRecordings < 2) ||
        (currentSentenceNumber > 300 && backgroundRecordings < 3);

      if (backgroundNoiseCheck) {
        this.setState({
          ...newState,
          backgroundNoiseCheckScreen: true,
          backgroundNoiseChecked: false,
          backgroundNoiseOk: null
        });
        return;
      }
    }

    if (currentSentenceNumber >= maximumRecordings) {
      this.setState({ ...newState, finished: true });
      return;
    }

    // more sentences still to record
    window.document.location.assign(
      window.document.location.href.replace(/[0-9]$/,
        (currentSentenceNumber + 1).toString())
    );
    // this.setState({
    //   ...newState,
    //   backgroundNoiseCheckScreen: false,
    //   backgroundNoiseChecked: false,
    //   backgroundNoiseOk: null,
    //   encouragingMessage: "",
    //   percentComplete: ((currentSentenceNumber + 1) / maximumRecordings) * 100,
    //   currentSentenceNumber: currentSentenceNumber + 1,
    //   currentSentence: this.state.currentSentences[currentSentenceNumber]
    // });
  }
  previousSentence(newState = {}, force = false) {
    const { isRecording, currentSentenceNumber, isUploading } = this.state;

    if (isUploading && !force) return;
    if (!(currentSentenceNumber > 1)) return;
    if (isRecording) this.stop();

    this.setState({
      ...newState,
      encouragingMessage: "",
      isRecording: false,
      percentComplete: ((currentSentenceNumber - 1) / maximumRecordings) * 100,
      currentSentenceNumber: currentSentenceNumber - 1,
      currentSentence: this.state.currentSentences[currentSentenceNumber - 2],
      waveFormImgSrc: null,
    });
  }
  toggle() {
    if (this.state.isRecording) this.stop();
    else this.start();
  }
  notGotStream(error) {
    console.error(error);
    if (
      window.confirm(
        "Sorry, something went wrong while trying to record your voice. If this keeps happening please contact us for assistance. The page will now reload to see if that helps to resolve the issue."
      )
    )
      window.document.location.reload();
  }
  playExample() {
    const existing = document.getElementById(playbackElementId);
    if (existing) return; // something is already playing
    const { isRecording, currentSentenceNumber } = this.state;
    if (isRecording) return;

    const audio = document.createElement("audio");
    audio.id = playbackElementId;
    audio.style.display = "none";
    audio.src = task1Audio[parseInt(currentSentenceNumber) - 1];
    audio.autoplay = true;
    audio.onended = () => audio.remove();
    document.body.appendChild(audio);
    return false;
  }
  play() {
    const somethingIsPlaying = document.getElementById(playbackElementId),
      { isRecording, token, currentSentenceHash, savedOk } = this.state,
      audioSrc = `${serverURL}listen/${currentSentenceHash || 'unknownHash'}/${token}.wav`,
      // ${token}.wav?t=${encodeURIComponent(
      //   currentSentence
      // )}&_=${escape(new Date().valueOf())}`,
      playIt = src => {
        const audio = document.createElement("audio");
        audio.id = playbackElementId;
        audio.style.display = "none";
        audio.src = src;
        audio.autoplay = true;
        audio.onended = () => audio.remove();
        document.body.appendChild(audio);
      };

    if (!(currentSentenceHash)) return;
    // if (!currentSentenceHash) return window.alert(`Sorry, cannot play this sample. If this keeps happening please contact us for assistance.`);

    if (somethingIsPlaying || isRecording)
      return this.stop(() => playIt(audioSrc)); // currentSentence, token));

    playIt(audioSrc); // (currentSentence, token);
    return false;
  }
  backgroundNoiseCheckScreen(isRecording) {
    const {
      backgroundRecordings,
      backgroundNoiseChecked,
      backgroundNoiseOk,
      backgroundProgressPercent
    } = this.state;

    // return <div>This is background</div>;

    if (backgroundNoiseChecked) return <div>Noise checked result: {backgroundNoiseOk ? "Ok" : "Not ok"}</div>;

    const progressCircle = (
      <div className="" style={{ margin: "15px" }}>
        {/* <Progress type="circle" percent={backgroundProgressPercent} /> */}
      </div>
    );
    if (backgroundProgressPercent >= 100) {
      this.stop(() => this.setState({ backgroundNoiseCheckScreen: false }));
    } else if (isRecording) {
      window.setTimeout(
        () =>
          this.setState({
            backgroundProgressPercent: backgroundProgressPercent + 1
          }),
        100
      );
    }
    return (
      <div className="container has-text-centered">
        <h3 className="title is-3" id="xxcurrentSentenceH">
          Record Room Noise
        </h3>
        <h4 className="title is-4">
          We would now like to record a few{" "}
          {!!backgroundRecordings && " more "}
          seconds of silence. This helps our algorithms distinguish your
          precise speech as accurately as possible. Please click the Record
          button below to continue.
        </h4>
        {isRecording ? (
          <button
            id="stopButton"
            className="button orange recButton isRecording buttonPurple buttonBottomSandyBrown"
            onClick={this.stop}
            style={{ width: 300 }}
          >
            <span style={{ paddingRight: 10 }}>
              <FontAwesomeIcon icon={faMicrophoneSlash} />
            </span>
            Stop
          </button>
        ) : (
          <button
            id="startButton"
            className="button orange recButton buttonPurple buttonBottomCerise"
            onClick={this.start}
            style={{ width: 300 }}
          >
            <span style={{ paddingRight: 10 }}>
              <FontAwesomeIcon icon={faMicrophone} />
              {/* <i className="fas fa-microphone isRecordingTxt" /> */}
            </span>
            Record
          </button>
        )}
        <br />
        {isRecording && progressCircle}
        &nbsp;
      </div>
    );
  }
  finishedRecording(token) {
    const finishedHTML = <div className="container-full">
      <div className="columns">
        <div className="column is-one-fifth"></div>
        <div className="column">
          <h1 className="title is-1">
            You have finished your recording.<br />
            Well done!
          </h1>
          <div className="has-text-centered"
            style={{ padding: 30 }}>
            <input
              type="button"
              value="Continue"
              className="button orange is-large beginButton"
              onClick={() => window.document.location.href = "/start"}
            />
          </div>
        </div>
        <div className="column is-one-fifth"></div>
      </div>
    </div>;

    fetch(finishedPingURL, { // ping first in 2021
      method: "POST", headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token })
    }).then(() => { });

    return finishedHTML;
  }
  microphoneSelectorPopup(selectStyle = {}) {
    const { microphoneDevices, microphoneDeviceId } = this.state;
    return <select
      style={{ minWidth: 180, fontSize: 'small', ...selectStyle }}
      value={microphoneDeviceId}
      onChange={e => {
        const { value } = e.target.options[e.target.selectedIndex],
          mics = this.state.microphoneDevices.filter(m => m.deviceId === value),
          { label } = mics[0],
          newState = {
            microphoneDeviceId: value,
            microphoneName: label
          };
        this.setState(newState);
        window.setTimeout(() => {
          this.activateMicrophone(
            {
              audio: { deviceId: value }
            },
            stream => window.setTimeout(() => {
              this.recorder.init(stream);
            }, 21)
          );
        }, 21); // 42
      }}
    >
      {microphoneDevices.length ?
        microphoneDevices.map((m, idx) => <option
          value={m.deviceId} key={idx}>{m.label}</option>) :
        <option value="">Loading...</option>}
    </select>;
  }
  microphoneSelectorRadio(style = {}) {
    const { microphoneDevices, microphoneDeviceId } = this.state,
      microphoneCheckVisualiser = <canvas
        id="micCanvas"
        width={visualiserWidth}
        height={visualiserHeight}
        style={{ border: "1px dotted #ccc" }}
      ></canvas>;

    return <div className="columns">
      <div className="column is-one-fifth"></div>
      <div className="column">
        <RadioGroup
          value={microphoneDeviceId}
          name="microphoneSelect"
          onChange={value => {
            const mics = this.state.microphoneDevices.filter(m => m.deviceId === value),
              { label } = mics[0],
              newState = {
                microphoneDeviceId: value,
                microphoneName: label,
                microphoneOptionClicked: true
              };
            this.setState(newState);
            window.setTimeout(() => {
              this.activateMicrophone(
                {
                  audio: { deviceId: value }
                },
                stream => window.setTimeout(() => {
                  this.recorder.init(stream);
                }, 21)
              );
            }, 21); // 42
          }}
        >
          <table className="table table-condensed" style={{ marginLeft: "auto", marginRight: "auto" }}>
            <tbody>
              {microphoneDevices.length ?
                microphoneDevices.map(d => <tr key={d.deviceId}>
                  <td>
                    <Radio id={`ra${d.deviceId}`}
                      value={d.deviceId} />
                  </td>
                  <td>
                    <label htmlFor={`ra${d.deviceId}`}>{d.label}</label>
                  </td>
                </tr>) : null}
            </tbody>
          </table>
        </RadioGroup>
      </div>
      <div className="column">
        <div className="columns">
          <div className="column is-narrow">
            {microphoneCheckVisualiser}
          </div>
          <div className="column has-text-left">
            <h4 className="title is-4">
              Not sure which microphone to use?
            </h4>
            <div style={{ fontSize: "10pt" }}>
              <p>We recommend you use an external, headset microphone that plugs into your device.</p>
              <p>A microphone like this might be called 'USB Microphone' or have the brand name of the microphone in the title.</p>
              <p>If you are still not sure which microphone is being used, you can select each microphone listed in turn, and tap the microphone. The volume indicator will respond to the microphone selected.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="column is-one-fifth">
      </div>
    </div>;
  }
  microphoneIntroScreen() {
    return <div className="columns">
      <div className="column is-10 is-offset-1">
        <div className="bigBoxes">
          <div className="has-text-centered">
            <h3 className="title is-3" style={{ marginBottom: 40 }}>
              Before we begin, we need to make sure the correct microphone is selected.<br /><br />
              It's important to use an external microphone,<br />as this gives much better quality.
            </h3>
            Click OK to continue:<br />
            <button className="button buttonPurple buttonBottomCerise"
              style={{ marginTop: '3px' }}
              onClick={() => {
                localStorage.setItem("microphoneIntroScreen", true);
                this.setState({ microphoneIntroScreen: true });
              }}>OK</button>
          </div>
        </div>
      </div>
    </div>;
  }
  microphoneConfirmation() {
    return <div className="columns">
      <div className="column is-10 is-offset-1">
        <div className="bigBoxes">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <h4 className="title is-4 has-text-centered">
                On the left, you will see a list of microphones our system has detected on your device.<br />
                Please use the circle button to select the microphone you wish to use for your recording.
              </h4>
            </div>
          </div>
          <div className="has-text-centered">
            {this.microphoneSelectorRadio({ fontSize: 'larger', minWidth: 450 })}<br />
          </div>
          <div className="columns">
            <div className="column has-text-centered" style={{ paddingTop: 30 }}>
              Once you have selected the correct microphone, please press continue.<br />
              <button className="button buttonPurple buttonBottomCerise"
                style={{ marginTop: '30px' }}
                onClick={() => {
                  localStorage.setItem("microphoneIsConfirmed", true);
                  this.setState({ microphoneIsConfirmed: true });
                }}>Continue</button>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
  mainScreen() {
    const {
      currentTaskNumber,
      currentSentenceNumber,
      isRecording,
      haveRecorded
    } = this.state,
      sentences = [`Please call Stella`,
        `Ask her to bring these things with her from the store`,
        `Six spoons of fresh snow peas, five thick slabs of blue cheese, and maybe a snack for her brother Bob.`,
        `We also need a small plastic snake and a big toy frog for the kids.`,
        `She can scoop these things into three red bags, and we will go meet her Wednesday at the train station.`],
      sen = sentences[
        parseInt(currentSentenceNumber) - 1
      ],
      currentSentence5 = currentSentenceNumber === 5, // on task1, whether we're on the fifth & final sentence
      canFinish = currentSentence5 && haveRecorded,
      orange = "#FFA10B",
      blue = "skyblue",
      task3TitleBoxStyle = { ...bigHeaderStyle, background: blue },
      notSelectedIcon = <FontAwesomeIcon icon={faSquare} style={{ color: "grey" }} size="2x" />,
      yesSelectedIcon = <FontAwesomeIcon icon={faCheckSquare} size="2x" />,
      task3QuestionLine = (question, questionNumber = 0, includeRecorder = true) => <div className="columns" style={{ marginTop: 3 }}
        id={`columnsQuestion${questionNumber}`}
      >
        <div className="column is-5 is-offset-2"
          id={`questionTextRow${questionNumber}`}
          style={{ paddingTop: 15, fontWeight: 600, border: `4px solid ${questionNumber === -1 ? null : 'darkorange'}` }}>
          <div className="columns">
            <div className="column" id={`questionTextColumn${questionNumber}`}>{question}</div>
            <div className="column is-narrow has-text-right">
              {questionNumber === -1 ?
                null :
                includeRecorder ?
                  <FontAwesomeIcon icon={faTrashAlt} className="grey" size="1x"
                    id={`binFor${questionNumber}`}
                    onClick={() => {
                      window.document.getElementById(`columnsQuestion${questionNumber}`).classList.add("is-hidden");
                    }}
                  /> : null}
              {questionNumber === -1 ? null :
                <canvas
                  className="is-hidden"
                  id={`myVisualiser${questionNumber}`}
                  width={visualiserWidth}
                  height={visualiserMiniHeight}
                  style={{ border: "1px dotted #ccc" }}
                ></canvas>}
            </div>
          </div>
        </div>
        {includeRecorder ?
          <div className="column is-1 has-text-centered" id={`boxforRecButton${questionNumber}`}
            style={{ fontWeight: 600, border: `4px solid ${questionNumber === -1 ? null : 'darkorange'}`, marginLeft: 18 }}>
            {questionNumber === -1 ? `Record` :
              <FontAwesomeIcon icon={faRecordVinyl} size="2x" id={`recbutton${questionNumber}`}
                title={`Click to record your answer to the question: ${question}`}
                style={{ color: "grey" }}
                onClick={() => {
                  const q = window.document.getElementById(`questionTextRow${questionNumber}`),
                    u = window.document.getElementById(`recbutton${questionNumber}`),
                    t = window.document.getElementById(`binFor${questionNumber}`),
                    v = window.document.getElementById(`myVisualiser${questionNumber}`),
                    x = window.document.getElementById(`nextButton`),
                    yesSel = window.document.getElementById(`yesSel${questionNumber}`),
                    notSel = window.document.getElementById(`notSel${questionNumber}`);

                  if (isRecording) {
                    u.style.color = "grey";
                    q.style.borderColor = "green";
                    t.classList.add("is-hidden");
                    v.classList.add("is-hidden");
                    x.classList.remove("is-hidden");
                    yesSel.classList.remove("is-hidden");
                    notSel.classList.add("is-hidden");
                    return this.stop();
                  }

                  u.style.color = "red";
                  q.style.borderColor = "red";
                  t.classList.add("is-hidden");
                  v.classList.remove("is-hidden");
                  this.setState({ recordingQuestionNumber: questionNumber });
                  this.start();
                }}
                onMouseOver={() => {
                  if (isRecording) return;
                  window.document.getElementById(`recbutton${questionNumber}`).style.color = "red";
                }}
                onMouseOut={() => {
                  if (isRecording) return;
                  window.document.getElementById(`recbutton${questionNumber}`).style.color = "grey";
                }}
              />}
          </div> : null}
        <div className="column is-1 has-text-centered"
          style={{ fontWeight: 600, border: `4px solid ${questionNumber === -1 ? null : 'darkorange'}`, marginLeft: 18 }}>
          {questionNumber === -1 ? (includeRecorder ? `Done` : `Choose`) : <div
            onClick={() => {
              const sel = window.document.getElementById(`yesSel${questionNumber}`),
                notSel = window.document.getElementById(`notSel${questionNumber}`),
                showHide = () => {
                  if ([...sel.classList].includes("is-hidden")) {
                    notSel.classList.add("is-hidden");
                    sel.classList.remove("is-hidden");
                    return;
                  }
                  sel.classList.add("is-hidden");
                  notSel.classList.remove("is-hidden");
                };
              if (includeRecorder) return;
              if (this.state.task3UserSelectedQuestions.length === 5 &&
                [...sel.classList].includes("is-hidden")) return;

              showHide();

              window.setTimeout(() => {
                let rv = [];
                for (let questionNumber in task3Questions) { // zero-based
                  const selectedIcon = window.document.getElementById(`yesSel${questionNumber}`),
                    questionElement = window.document.getElementById(`questionTextColumn${questionNumber}`),
                    questionText = questionElement ? questionElement.innerText : null;

                  if (!selectedIcon) continue;
                  if ([...selectedIcon.classList].includes("is-hidden")) continue;
                  if (!questionText) continue;

                  rv.push({ questionIdx: questionNumber, text: questionText });
                }
                this.setState({ task3UserSelectedQuestions: rv });
                fetch(apiPath("setTask3Questions"), {
                  method: "POST", headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({ at: new Date(), questions: rv, user: localStorage.getItem("i") })
                }).then(() => { });
              }, 143);
            }}
          >
            <div id={`notSel${questionNumber}`} className={
              this.state.task3UserSelectedQuestions.length ? (
                this.state.task3UserSelectedQuestions.map(m => m.text).includes(question) ? "is-hidden" : null
              ) : null
            }>{notSelectedIcon}</div>
            <div id={`yesSel${questionNumber}`} className={
              this.state.task3UserSelectedQuestions.length ? (
                this.state.task3UserSelectedQuestions.map(m => m.text).includes(question) ? null : "is-hidden"
              ) : "is-hidden"
            }>{yesSelectedIcon}</div>
          </div>}
        </div>
      </div>,
      task3ChooseQuestions = <div>
        <div style={task3TitleBoxStyle} className="has-text-centered" >
          task 3: Choose Your Questions
        </div>
        <h4 className="title is-4 has-text-centered">
          Please select 5 questions you would like to answer in this task from the list below:
        </h4>
        {task3QuestionLine("Question", -1, false)}
        {task3Questions.map((q, i) => task3QuestionLine(q[0], i, false))}
        {/* {JSON.stringify(this.state.task3UserSelectedQuestions)} */}
        <div className="has-text-centered" style={{ marginBottom: 20 }}>
          {
            this.state.task3UserSelectedQuestions.length >= 5 ?
              <button onClick={() => {
                this.setState({ task3ScreenQuestionNumber: 1 });
              }}
              >Continue
                <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: 8 }} />
              </button> : <div style={{ minHeight: 57 }}>&nbsp;</div>
          }
        </div>
        {smallWhoAmI(this.state.userData)}
      </div>,
      task3Screen1 = task3ChooseQuestions,
      task3SingleQuestion = questionNumber => {
        const canFinish = questionNumber === 5,
          buttonPanel = <div className="columns"
            style={{ paddingTop: 66, paddingBottom: 55 }}
          >
            <div className="column has-text-right is-narrow is-hidden-touch"
              style={{ backgroundColor: "white", marginLeft: 80 }}
            >
              <img id="recordingButtonPrevious"
                onClick={() => {
                  if (questionNumber === 1) return false;
                  this.setState({ task3ScreenQuestionNumber: questionNumber - 1 });
                }}
                style={{ borderWidth: 0, outline: 'none' }}
                title="Click this to go back to the previous sentence"
                src="https://www.speakunique.co.uk/record-my-voice/Previous.png" alt="previous"
                width="68" height="71" className="" />
            </div>
            <div className="column has-text-centered is-one-third">
              <div className="columns">
                <div className="column is-hidden-touch is-hidden">
                  <img className="is-hidden-touch" title="Click this button to listen to your recording"
                    id="recordingButtonPlay"
                    onClick={this.play}
                    style={{ borderWidth: 0, outline: 'none', marginLeft: 10, marginRight: 10 }}
                    src="https://www.speakunique.co.uk/record-my-voice/Play.png"
                    // width="138" height="138"
                    alt="Play" />
                </div>
                <div className="column">
                  <img className="" title="Click this button to start/stop recording" id="startButton"
                    onClick={() => { isRecording ? this.stop() : this.start() }}
                    style={{
                      borderWidth: 0, outline: 'none',
                      marginLeft: 10, marginRight: 10, padding: 0, border: 'none',
                      background: 'none'
                    }}
                    src={`https://www.speakunique.co.uk/record-my-voice/${isRecording ? 'Stop.png' : 'Record.png'}`}
                    width="138" height="138" alt="Record" />
                </div>
              </div>
            </div>
            <div className="column has-text-left is-hidden-touch">
              {canFinish ? <button
                style={{ marginTop: 26, marginLeft: 20 }}
                onClick={() => { window.document.location.assign("/finished") }}
                className="">Continue<FontAwesomeIcon
                  icon={faArrowRight} style={{ marginLeft: 8 }} /></button> :
                (currentSentence5 ? null :
                  <div
                    onClick={() => {
                      if (isRecording) this.stop();
                      window.setTimeout(() => {
                        this.setState({ task3ScreenQuestionNumber: questionNumber + 1 });
                        return false;
                      }, 143);
                    }}
                  >
                    <img id="recordingButtonNext" className="" title="Click this to proceed to the next sentence"
                      // aria-disabled={isRecording}
                      src="https://www.speakunique.co.uk/record-my-voice/Next.png" alt="next" width="68" height="71"
                    /><br />
                    When you have finished recording this question,<br />click to go to the next question.
                  </div>)}
            </div>
          </div>,
          progressBar = <Progress style={{ marginRight: 10 }}
            data-tip={`This bar displays your progress (${maximumRecordings})`}
            theme={{
              default: { color: "#F2936F" },
              active: { color: "#F2936F" }
            }}
            percent={Math.floor(parseFloat((questionNumber - 1) / 5 * 100))}
          />,
          questionText = this.state.task3UserSelectedQuestions[questionNumber - 1].text,
          recorder = <div className="columns">
            <div className="column is-narrow">
              <canvas
                id="myCanvas"
                width={visualiserWidth}
                height={visualiserHeight}
                style={{ border: "1px dotted #ccc" }}
              ></canvas>
              <br />
              <FontAwesomeIcon icon={faMicrophone}
                title={`Reset microphone`}
                onClick={() => {
                  localStorage.removeItem("microphoneName");
                  localStorage.removeItem("microphoneDeviceId");
                  localStorage.removeItem("microphoneIsConfirmed");
                  localStorage.removeItem("microphoneIntroScreen");
                  window.document.location.reload();
                }}
              />
            </div>
            <div className="column">
              <h2 className="title is-2" style={{ marginLeft: 50 }}>
                {questionText}
              </h2>
              {buttonPanel}
            </div>
            {smallWhoAmI(this.state.userData)}
          </div>;

        return <div style={{ paddingLeft: 5, paddingRight: 10 }}>
          <div className="columns">
            <div className="column is-12">
              <div style={task3TitleBoxStyle} className="has-text-centered">
                task 3: answer the question
              </div>
              <div className="columns">
                <div className="column is-one-fifth" style={{ paddingTop: 33, paddingLeft: 24 }}>
                  <div style={{
                    minHeight: 283
                  }}>
                    <div style={{
                      fontSize: "smaller", minHeight: 202,
                      border: "3px solid darkorange",
                      paddingLeft: 14, paddingRight: 24, paddingTop: 8, paddingBottom: 20
                    }}>
                      <div className="has-text-centered" style={{ fontWeight: 600 }}>
                        Not sure what to say?
                      </div>
                      <br />
                      {
                        prompts[questionText] ? <div>
                          Can you tell us:<ul style={{ marginTop: 7, paddingLeft: 12 }}>
                            {prompts[questionText].map((p, idx) => <li key={`help${idx}`}>- {p}</li>)}
                          </ul>
                        </div> : <div>Just make something up!</div>
                      }
                    </div>
                  </div>
                </div>
                <div className="column">
                  {recorder}
                </div>
              </div>
            </div>
          </div>
          {progressBar}
        </div>;
      },
      task2Screen = picNum => <div>
        <div className="columns">
          <div className="column is-12">
            <div style={greenBackgroundStyle} className="has-text-centered">
              task 2: describe the picture
            </div>
            <div className="columns">
              <div className="column is-narrow" style={{ paddingTop: 33, paddingLeft: 24 }}>
                <div style={{
                  minHeight: task2ColumnsMinHeight,
                }}>
                  <div style={{
                    fontSize: "smaller", minHeight: 202,
                    border: "4px solid darkorange", maxWidth: 210,
                    paddingLeft: 4, paddingRight: 4, paddingTop: 2, paddingBottom: 4
                  }}>
                    <div className="has-text-centered" style={{ fontWeight: 600 }}>
                      Not sure what to say?
                    </div>
                    <br />
                    Can you tell us:<ul style={{ marginTop: 7, paddingLeft: 12 }}>
                      {task2PicturePrompts[picNum - 1] ? task2PicturePrompts[picNum - 1].map((prompt, idx) => <li style={{
                        paddingBottom: 8
                      }}
                        key={idx}>- {prompt}</li>) : null}
                    </ul>
                  </div>
                </div>
                <div className="has-text-right">
                  <a href={
                    currentSentenceNumber > 1 ?
                      `task-2-${currentSentenceNumber - 1}` :
                      `start`}>
                    <button>
                      <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 6 }} />
                      Previous
                    </button>
                  </a>
                </div>
              </div>
              <div className="column has-text-centered is-narrow">
                <img style={{ height: 490 }} src={task2ImageSrcs[
                  picNum - 1
                ]} />
              </div>
              <div className="column has-text-left is-narrow" style={{ paddingRight: 30, paddingTop: 33 }}>
                <div style={{ minHeight: task2ColumnsMinHeight }}>
                  <img className="" title="Click this button to start/stop recording" id="startButton"
                    onClick={() => { isRecording ? this.stop() : this.start() }}
                    style={{
                      borderWidth: 0, outline: 'none',
                      marginLeft: 10, marginRight: 10, padding: 0, border: 'none',
                      background: 'none'
                    }}
                    src={`https://www.speakunique.co.uk/record-my-voice/${isRecording ? 'Stop.png' : 'Record.png'}`}
                    width="138" height="138" alt="Record" /><br />
                  <img className="" title="Click this button to listen to your recording" id="playButton"
                    onClick={() => this.state.currentSentenceHash ? this.play() : null}
                    style={{
                      borderWidth: 0, outline: 'none',
                      marginLeft: 10, marginRight: 10, padding: 0, border: 'none',
                      background: 'none',
                      opacity: this.state.currentSentenceHash ? 1 : 0.33
                    }}
                    src={`https://www.speakunique.co.uk/record-my-voice/Play.png`}
                    width="138" height="138" alt="Play" /><br />
                  <div id="loadingMessage" className="has-text-right"></div>
                </div>
                <div className="has-text-left">
                  {picNum === task2ImageSrcs.length ?
                    <button
                      onClick={e => {
                        e.preventDefault();
                        window.document.location.assign(`/start`);
                      }}
                      style={{ opacity: haveRecorded ? null : 0.4 }}
                    >Continue <FontAwesomeIcon icon={faArrowAltCircleRight} style={{ marginLeft: 6 }} />
                    </button> : <button
                      style={{ opacity: haveRecorded ? null : 0.4 }}
                      onClick={e => {
                        e.preventDefault();
                        window.document.location.assign(`/task-2-${picNum + 1}`);
                      }}
                    >Next
                      <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: 6 }} />
                    </button>}
                </div>
              </div>
              <div className="column is-narrow" style={{ paddingTop: 27 }}>
                <canvas
                  id="myCanvas"
                  width={visualiserWidth}
                  height={visualiserHeight}
                  style={{ border: "1px dotted #ccc" }}
                ></canvas>
                <br />
                <FontAwesomeIcon icon={faMicrophone}
                  title={`Click to reset your microphone settings`}
                  size="3x"
                  style={{
                    color: "rgb(233,233,233)"
                  }}
                  onClick={() => {
                    localStorage.removeItem("microphoneName");
                    localStorage.removeItem("microphoneDeviceId");
                    localStorage.removeItem("microphoneIsConfirmed");
                    localStorage.removeItem("microphoneIntroScreen");
                    window.document.location.reload();
                  }}
                />
              </div>
            </div>
            <Progress
              data-tip={`This bar displays your progress`}
              theme={{
                default: { color: "#F2936F" },
                success: { color: "#F2936F" },
                active: { color: "#F2936F" }
              }}
              percent={Math.floor(parseFloat(100 / task2ImageSrcs.length * (picNum - (!haveRecorded ? 1 : 0))))}
            />
          </div>
        </div>
        {smallWhoAmI(this.state.userData)}
      </div>;

    if (currentTaskNumber === 2) return task2Screen(currentSentenceNumber);
    if (currentTaskNumber === 3) {
      if (!!this.state.task3ScreenQuestionNumber) { // task 3 specific question
        return task3SingleQuestion(
          this.state.task3ScreenQuestionNumber,
          this.state.task3UserSelectedQuestions[
            this.state.task3ScreenQuestionNumber - 1
          ].text);
      }
      return task3Screen1; // task 3 choose questions
    }

    const buttonPanel = <div className="columns"
      style={{ paddingTop: 26, paddingBottom: 15 }}
    >
      <div className="column has-text-right is-hidden-touch is-one-quarter"
        style={{ backgroundColor: "white" }}
      >
        <img id="recordingButtonPrevious"
          onClick={() => {
            if (window.document.location.pathname.match(/-1$/))
              return window.document.location.assign("/start");
            if (!window.document.location.pathname.match(/-[2-5]$/)) return;
            window.document.location.assign(
              window.document.location.pathname.replace(/\d$/, currentSentenceNumber - 1)
            );
          }}
          style={{ borderWidth: 0, outline: 'none' }}
          title="Click this to go back to the previous sentence"
          src="https://www.speakunique.co.uk/record-my-voice/Previous.png" alt="previous"
          width="68" height="71" className="" />
      </div>
      <div className="column is-narrow is-hidden-touch is-one-quarter">
        <img className="" title="Click this button to listen to your recording"
          id="recordingButtonPlay"
          style={{ borderWidth: 0, outline: 'none', marginLeft: 10, marginRight: 10, maxWidth: 138, maxHeight: 138 }}
          onClick={this.play}
          src="https://www.speakunique.co.uk/record-my-voice/Play.png"
          width="138" height="138"
          alt="Play" />
      </div>
      <div className="column is-narrow is-one-quarter">
        <img className="" title="Click this button to start/stop recording" id="startButton"
          onClick={() => { isRecording ? this.stop() : this.start() }}
          style={{
            borderWidth: 0, outline: 'none',
            marginLeft: 10, marginRight: 10, padding: 0, border: 'none',
            background: 'none'
          }}
          src={`https://www.speakunique.co.uk/record-my-voice/${isRecording ? 'Stop.png' : 'Record.png'}`}
          width="138" height="138" alt="Record" />
      </div>
      <div className="column is-hidden-touch is-one-quarter has-text-centered">
        {canFinish ? <>
          <button
            style={{ marginTop: 26, marginLeft: 20 }}
            onClick={() => { window.document.location.assign("/start-task-2") }}
            className="">Continue <FontAwesomeIcon
              icon={faArrowRight} style={{ marginLeft: 8 }} />
          </button><br />
          <button
            style={{ marginTop: 26, marginLeft: 20 }}
            onClick={() => { window.document.location.assign("/start") }}
          >
            <FontAwesomeIcon icon={faArrowAltCircleLeft}
              style={{ marginRight: 11 }} />
            Return to Home
          </button>
        </>
          :
          (currentSentence5 ? null :
            <img id="recordingButtonNext" className="" title="Click this to proceed to the next sentence"
              onClick={() => {
                // console.info(`Going to next sentence`);
                this.nextSentence();
              }}
              src="https://www.speakunique.co.uk/record-my-voice/Next.png" alt="next" width="68" height="71"
            />)}
      </div>
    </div>,
      progressBar = <>
        <Progress
          data-tip={`This bar displays your progress`}
          theme={{
            default: { color: "#F2936F" },
            active: { color: "#F2936F" }
          }}
          percent={Math.floor(((parseInt(currentSentenceNumber) - (haveRecorded ? 0 : 1)) / sentences.length * 100))}
        />
        <div id="loadingMessage" className="has-text-right"></div>
      </>;

    return <div className="columns">
      <div className="column is-10 is-offset-1 has-text-centered">
        <div style={headerBackgroundStyle} >
          task 1: read the sentence
        </div>
        <div className="columns">
          <div className="column is-narrow">
            <canvas
              id="myCanvas"
              width={visualiserWidth}
              height={visualiserHeight}
              style={{ border: "1px dotted #ccc" }}
            ></canvas>
            <br />
            <FontAwesomeIcon icon={faMicrophone}
              title={`Reset microphone`}
              style={{ color: "rgb(230,230,230)" }}
              onClick={() => {
                localStorage.removeItem("microphoneName");
                localStorage.removeItem("microphoneDeviceId");
                localStorage.removeItem("microphoneIsConfirmed");
                localStorage.removeItem("microphoneIntroScreen");
                window.document.location.reload();
              }}
            />
          </div>
          <div className="column">
            <h2 className="title is-2 has-text-centered">
              {sen}
            </h2>
            {buttonPanel}
          </div>
          <div className="column is-one-fifth is-narrow has-text-left is-small"
            title="Click to hear someone saying these words">
            <FontAwesomeIcon icon={faQuestionCircle} size="3x"
              onClick={this.playExample}
              className="grey"
            /><br />
            Not sure what the sentence says?<br />
            Click to listen to someone saying this sentence.
          </div>
        </div>
        {progressBar}
        {smallWhoAmI(this.state.userData)}
      </div>
    </div>;
  }
};
