const prompts = {
  "Tell me about your favourite animal": [
    `What does it look like?`,
    `What does it eat?`,
    `Where does it live?`
  ],
  "What are your three favourite things, and why?": [
    `Is it a toy?`,
    `Is it an iPad or TV?`,
    `Is it books/a game?`,
    `Why it's your favourite thing?`
  ],
  "Do you prefer the beach or the park?": [
    `Why?`,
    `What do you do there?`,
    `What do you play with?`
  ],
  "If you could wake up with a superpower, what would it be?": [
    `Maybe it would be flying, being invisible, being super strong`,
    `Why do you want this super power?`,
    `Is it the same power as a superhero?`
  ],
  "What rules would you make in school?": [
    `What would you do to snack time and play time?`,
    `Would you make school days longer or shorter?`
  ],
  "What do you do when you get home from school?": [
    `What snack do you have?`,
    `Do you play or watch TV?`
  ],
  "What’s your favourite day of the week, and why?": [
    `What do you do on this day?`,
    `Is it a busy day or a quiet day?`,
    `Why is it your favourite?`
  ]
};

module.exports = {
  prompts
};
