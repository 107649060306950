import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";

const { loadHelloData, smallWhoAmI } = require("./_Util");
const boxMinHeight = 271;

export const RegisteredWelcome4Boxes = _props => { // every time, registered home page

  const [userState, setUserState] = useState({}),
    [task1Label, setTask1Label] = useState("Start"),
    [task2Label, setTask2Label] = useState("Start"),
    [task3Label, setTask3Label] = useState("Start"),
    [task1Url, setTask1Url] = useState("/start-task-1"),
    [task2Url, setTask2Url] = useState("/start-task-2"),
    [task3Url, setTask3Url] = useState("/start-task-3"),
    userId = localStorage.getItem("i");

  if (!userId) window.document.location.assign("/");

  useEffect(() => {
    loadHelloData(userId, data => {
      setUserState(data);
      // if (data.consentsMissing && data.consentsMissing.length)
      //   return window.document.location.assign(
      //     data.consentsMissing[0] === "parent" ? "/3" : "/3a");

      const getLabel = taskCount =>
        taskCount >= 5 ? `Complete`
          : `Resume`;
      if (data.task1count > 0) {
        setTask1Label(getLabel(data.task1count));
        setTask1Url(`/task-1-${data.task1count >= 5 ? 1 : data.task1count}`);
      }
      if (data.task2count > 0) {
        setTask2Label(getLabel(data.task2count));
        setTask2Url(`/task-2-${data.task2count}`);
      }
      if (data.task3count > 0) {
        setTask3Label(getLabel(data.task3count));
        setTask3Url(`/task-3-1`); // ${data.task3count}`);
      }
    });
  }, []);

  return <div>
    <div className="columns">
      <div className="column is-10 is-offset-1">
        <div className="columns">
          <div className="column bigBoxes"
            style={{}}
          >
            <h4 className="title is-4">
              Information and Support Documents
            </h4>
            1) <a className="orange" href="/recording-tips">Recording Instructions</a><br /><br />
            2) <a className="orange" href="/1?">Information packages</a><br /><br />
            <hr />
            {userState.pv ? userState.pv.age || "" : ""} {
              userState.pv ? userState.pv.sex || "" : ""}<br />
            {userState.pv ? userState.pv.accentRegion || "" : ""}<br />
            {userState.pv ? userState.pv.accentCountry || "" : ""}
            <hr />
          </div>
          <div className="column bigBoxes">
            <strong>Task 1:<br />
              Read a story</strong><br /><br />
            <div style={{ minHeight: boxMinHeight }}>
              <p style={{ marginTop: 1 }}>
                In this task you will
                be asked to read a short
                story.
              </p>
              <p style={{ marginTop: 9 }}>
                Don't worry if you
                struggle with any of the
                words, you can listen to
                the sentence before you
                read it.
              </p>
            </div>
            <p style={{ marginTop: 6 }}>
              Time: About 5 minutes
            </p>
            <div className="has-text-centered"
              style={{ opacity: task1Label === 'Complete' ? 0.4 : null }}>
              <a href={task1Url}
                className="button"
                onClick={() => {
                  if (!(task1Label !== `Complete` || window.confirm(`You have already completed this task. Do you want to do it again?`))) return false;
                }}>
                {task1Label}
                {task1Label === `Complete` ?
                  <FontAwesomeIcon icon={faCheckSquare} style={{ marginLeft: 11 }} /> : null}
              </a>
            </div>
          </div>
          <div className="column bigBoxes">
            <strong>Task 2:<br />
              Describe the picture</strong><br /><br />
            <div style={{ minHeight: boxMinHeight }}>

              <p style={{ marginTop: 1 }}>
                In this task you will
                be asked to describe
                a picture.

              </p>
              <p style={{ marginTop: 9 }}>
                We want you to give
                as much detail as
                possible when describing
                the image.
              </p>
            </div>
            <p style={{ marginTop: 6 }}>
              Time: About 10 minutes
            </p>
            <div className="has-text-centered"
              style={{ opacity: task2Label === 'Complete' ? 0.4 : null }}>
              <a href={task2Url}
                className="button"
                onClick={() => {
                  if (!(task2Label !== `Complete` || window.confirm(`You have already completed this task. Do you want to do it again?`))) return false;
                }}>
                {task2Label}
                {task2Label === `Complete` ?
                  <FontAwesomeIcon icon={faCheckSquare} style={{ marginLeft: 11 }} /> : null}
              </a>
            </div>
          </div>
          <div className="column bigBoxes">
            <strong>Task 3:<br />
              Answering questions</strong><br /><br />
            <div style={{ minHeight: boxMinHeight }}>
              <p style={{ marginTop: 1 }}>
                In this task you will be asked to answer some questions.
              </p>
              <p style={{ marginTop: 9 }}>
                These might be about a picture you are shown or about your interests and hobbies.
              </p>
            </div>
            <p style={{ marginTop: 6 }}>
              Time: About 10 minutes
            </p>
            <div className="has-text-centered"
              style={{ opacity: task3Label === 'Complete' ? 0.4 : null }}>
              <a href={task3Url}
                className="button"
                onClick={() => {
                  if (!(task3Label !== `Complete` || window.confirm(`You have already completed this task. Do you want to do it again?`))) return false;
                }}>
                {task3Label}
                {task3Label === `Complete` ?
                  <FontAwesomeIcon icon={faCheckSquare} style={{ marginLeft: 11 }} /> : null}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    {smallWhoAmI(userState)}
  </div>;
};
