import React, { useState, useEffect } from "react";
import { RadioGroup, Radio } from "react-radio-group";
import CreatableSelect from 'react-select/creatable';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const { jsonHeadersPostForm, apiPath } = require("./_Util");

export const Step2Register = _props => {
  const [code, setCode] = useState(""),
    [password, setPassword] = useState(),
    [age, setAge] = useState(),
    [sex, setSex] = useState(),
    [countries, setCountries] = useState([]),
    [accentCountry, setAccentCountry] = useState(),
    [regions, setRegions] = useState({}),
    [accentRegion, setAccentRegion] = useState(),
    formUrl = apiPath("register"),
    submitForm = e => {
      e.preventDefault();

      if (accentCountry === "United Kingdom" && accentRegion && accentRegion.match(/^[- ]+/)) {
        window.alert(`Please select one of the regions within ${accentRegion.replace(/^[- ]+/, "")}.`);
        window.document.getElementById("accentRegionSelect").focus();
        return false;
      }

      const vals = {
        code, password, age, sex,
        accentCountry,
        accentRegion
      };
      fetch(formUrl, {
        ...jsonHeadersPostForm,
        body: JSON.stringify(vals)
      })
        .then(r => r.ok ? r.json() : {})
        .then(data => {
          // console.info(data);
          if (!!data.error) return window.alert(`Sorry, error: ${data.error}`);
          if (data && data.i) localStorage.setItem("i", data.i);
          if (data && data.url) return window.document.location.assign(data.url);
        });
    };

  useEffect(() => {
    const getAccents = async () => {
      const response = await fetch(apiPath("accents")),
        result = response.ok ? await response.json() : {};

      if (!result.influences) return;

      setCountries(result.regions);
      setRegions(result.influences);
    };

    // console.info(`Using effect at ${new Date()}.`);
    // console.info(accentCountry, accentRegion);
    if (countries.length) return; // already done
    getAccents();
    window.document.getElementById("upcInput").focus();
  }, [countries, regions, accentCountry, accentRegion]);

  const bigStyle = { fontSize: "23px" },
    bigInputStyle = { ...bigStyle, border: "5px solid rgb(239, 194, 82)", width: 393 },
    devColumnStyle = { border: '1px dotted grey' },
    pageColumnStyle = window.document.hostname === "localhost" ? devColumnStyle : {};

  return <div>
    <form>
      <div className="columns">
        <div className="column is-10 is-offset-1" style={pageColumnStyle}>
          <h3 className="title is-3">
            Registration
          </h3>
          Please complete the registration details below.<br />
          Setting a password will allow you to complete the recording across multiple sessions.<br />
          <br />
          If the code on your pack is longer than six digits please only enter the first six digits.<br />
          <br />
          <table className="table is-table is-fullwidth">
            <tbody>
              <tr style={bigStyle}>
                <td>Unique participant code <FontAwesomeIcon icon={faQuestionCircle}
                  onClick={() => {
                    const c = window.document.getElementById("upcHelp");
                    if (c.classList.contains("is-hidden")) return c.classList.remove("is-hidden");
                    c.classList.add("is-hidden");
                  }}
                />
                  <div className="is-hidden" id="upcHelp">
                    You can find your Unique Participant Code on the information letter sent home to you.
                  </div>
                </td>
                <td><input id="upcInput" name="particCode" className="regInput boxShadow"
                  placeholder="" style={bigInputStyle}
                  type="numeric" required={true} value={code} onChange={e => setCode(e.target.value)} /></td>
              </tr>
              <tr style={bigStyle}>
                <td>Password</td>
                <td><input name="chosenPassword" style={bigInputStyle}
                  size={32} maxLength={64}
                  type="text" required={true} value={password} onChange={e => setPassword(e.target.value)} /></td>
              </tr>
              <tr style={bigStyle}>
                <td colSpan={2}>
                  <div className="columns is-gapless">
                    <div className="column">
                      Accent
                    </div>
                    <div className="column">
                      <CreatableSelect
                        defaultValue={{ value: "", label: "Select country..." }}
                        // value={{ value: accentCountry, label: accentCountry }}
                        onChange={(val) => {
                          setAccentCountry(val.value);
                          setAccentRegion(null);
                        }}
                        style={bigInputStyle}
                        options={[
                          // { value: "", label: "Select country..." },
                          ...countries.map(c => { return { value: c, label: c } })
                        ]} />
                    </div>
                    <div className="column">
                      <CreatableSelect
                        className={accentCountry && regions[accentCountry] && regions[accentCountry].length ? null : "is-hidden"}
                        id="accentRegionSelect"
                        // value={{ value: accentRegion, label: accentRegion }}
                        defaultValue={{ value: "", label: `Select area...` }}
                        onChange={(v) => setAccentRegion(v.value)}
                        style={bigInputStyle}
                        options={accentCountry && regions[accentCountry] ?
                          regions[accentCountry].map(c => { return { value: c, label: c } }) : null} />
                    </div>
                  </div>
                </td>
              </tr>
              <tr style={bigStyle}>
                <td>Age</td>
                <td>
                  <select name="age" required={true} value={age}
                    style={{
                      fontSize: 'larger',
                      minWidth: 260,
                      ...bigInputStyle
                    }}
                    onChange={e => setAge(e.target.value)} >
                    <option value={null}>Select your age</option>
                    {Array(9).fill(null).map((_, idx) => <option value={idx + 7}>{idx + 7}</option>)}
                  </select>
                </td>
              </tr>
              <tr style={bigStyle}>
                <td>
                  Gender
                </td><td>
                  <RadioGroup
                    required={true}
                    name="sex" id="sexM"
                    selectedValue={sex}
                    onChange={val => {
                      document.getElementById("genderDiv").classList.remove("is-danger");
                      setSex(val);
                    }}
                  >
                    <div className="columns is-gapless"
                      style={{ paddingTop: 11 }}
                      id="genderDiv">
                      <div className="column is-narrow">
                        <label htmlFor="gFemale">
                          Female&nbsp;
                          <Radio
                            id="gFemale"
                            value="female"
                            className="radio genderRadio"
                          />
                        </label>
                      </div>
                      <div className="column has-text-center is-narrow"
                        style={{ marginLeft: 50, marginRight: 50 }}
                      >
                        <label htmlFor="gMale">
                          Male&nbsp;
                          <Radio id="gMale" value="male" className="radio genderRadio" />
                        </label>
                      </div>
                      <div className="column is-narrow has-text-left">
                        <label htmlFor="gOther">
                          Other&nbsp;
                          <Radio
                            id="gOther"
                            value="other"
                            className="radio genderRadio"
                          />
                        </label>
                      </div>
                    </div>
                  </RadioGroup>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="has-text-right">
            <button className="spunButton"
              onClick={submitForm}
              style={{ margin: 20 }}
            >Register</button>
          </div>
        </div>
      </div>
    </form>
    <div className="columns">
      <div className="column is-offset-1">
        If you have already created an account please <a href={'/l'}
          style={{ fontWeight: "bold" }}
          className="orange hinted">log in here</a>.
      </div>
      <div className="column is-narrow has-text-right" style={{ paddingRight: 40, fontWeight: 600 }}>
        Registration: Step 1/3
      </div>
    </div>
  </div>;
};
