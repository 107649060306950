import React, { useState, useEffect } from "react";
import { RadioGroup, Radio } from "react-radio-group";

const { loadHelloData } = require("./_Util");

const footerSteps = stepNo => <div className="columns">
  <div className="column is-offset-1">
  </div>
  <div className="column is-narrow has-text-right" style={{ paddingRight: 40, fontWeight: 600 }}>
    Registration: Step {stepNo}/3
  </div>
</div>;

export const Step3ParentalConsent = _props => {
  const [understood, setUnderstood] = useState(),
    [asked, setAsked] = useState(),
    [answered, setAnswered] = useState(),
    [told, setTold] = useState(),
    [canStop, setCanStop] = useState(),
    [happy, setHappy] = useState(),
    [uniSynth, setUniSynth] = useState(),
    [uniUse, setUniUse] = useState(),
    [noPayment, setNoPayment] = useState(),
    [agreeTaking, setAgreeTaking] = useState(),
    [helloData, setHelloData] = useState({}),
    yes = "yes",
    allYes = () => !!(
      understood === yes && asked === yes && answered === yes && told === yes &&
      canStop === yes && happy === yes && uniSynth === yes && uniUse === yes &&
      noPayment === yes && agreeTaking === yes
    ),
    userId = localStorage.getItem("i");

  if (!userId) window.document.location.assign("/");

  useEffect(() => {
    loadHelloData(userId, setHelloData)
  }, []);

  return <div>
    <div className="columns">
      <div className="column is-10 is-offset-1">
        <h3 className="title is-3">
          Registration
        </h3>
        Please ask your parent to read the information below and provide consent for you to take part in the project.
        <br /><br />
        This form is required to be completed prior to the recording taking place. Please read it carefully. You can ask the research team any questions that you may have. <br /><br />
        The University of Edinburgh is a charitable body registered in Scotland (No: SC005336), Old College, South Bridge, Edinburgh EH8 9YL. In this project “the University” will record the Contributor speaking aloud (the “Contribution”).
        <table className="table table-condensed">
          <thead>
            <tr>
              <th><br /></th>
              <th>Yes</th>
              <th>No</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>I have read and understand the Parent Information Sheet, Version 1.0, 20/11/2021 for the above study. I have had the opportunity to consider the information, ask questions and have had these questions answered satisfactorily.</td>
              <td colSpan="2">
                <RadioGroup
                  name="asked" id="asked"
                  selectedValue={asked}
                  onChange={val => {
                    setAsked(val);
                    // setAllYes();
                  }}
                >
                  <div className="columns">
                    <div className="column has-text-centered">
                      <Radio id="askedY" value="yes" className="radio" />
                    </div>
                    <div className="column has-text-centered">
                      <Radio id="askedN" value="no" className="radio" />
                    </div>
                  </div>
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>
                I understand that my child's participation is voluntary and that I am free to withdraw them at any time, without giving any reason and without my legal rights being affected.
              </td>
              <td colSpan="2">
                <RadioGroup
                  name="understood" id="understood"
                  selectedValue={understood}
                  onChange={val => {
                    setUnderstood(val);
                    // setAllYes();

                  }}
                >
                  <div className="columns">
                    <div className="column has-text-centered">
                      <Radio id="understoodY" value="yes" className="radio" />
                    </div>
                    <div className="column has-text-centered">
                      <Radio id="understoodN" value="no" className="radio" />
                    </div>
                  </div>
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>
                I understand that data collected during the study may be looked at by individuals from the Sponsor (University of Edinburgh) and from regulatory authorities. I give permission for these individuals to have access to my child's voice recording.
              </td>
              <td colSpan="2">
                <RadioGroup
                  name="answered" id="answered"
                  selectedValue={answered}
                  onChange={val => {
                    setAnswered(val);
                    // setAllYes();

                  }}
                >
                  <div className="columns">
                    <div className="column has-text-centered">
                      <Radio id="answeredY" value="yes" className="radio" />
                    </div>
                    <div className="column has-text-centered">
                      <Radio id="answeredN" value="no" className="radio" />
                    </div>
                  </div>
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>
                I understand that my child’s recording will be anonymised through a unique identifier and no additional personal data about my child will be collected.
              </td>
              <td colSpan="2">
                <RadioGroup
                  name="told" id="told"
                  selectedValue={told}
                  onChange={val => {
                    setTold(val);
                    // setAllYes();

                  }}
                >
                  <div className="columns">
                    <div className="column has-text-centered">
                      <Radio id="toldY" value="yes" className="radio" />
                    </div>
                    <div className="column has-text-centered">
                      <Radio id="toldN" value="no" className="radio" />
                    </div>
                  </div>
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>
                I agree to the recording of my child’s Contribution and grant to the University all rights, without payment, and without limitation, all consents necessary to enable the University to make the fullest use of the Contribution worldwide.
              </td>
              <td colSpan="2">
                <RadioGroup
                  name="canStop" id="canStop"
                  selectedValue={canStop}
                  onChange={val => {
                    setCanStop(val); // setAllYes();

                  }}
                >
                  <div className="columns">
                    <div className="column has-text-centered">
                      <Radio id="canStopY" value="yes" className="radio" />
                    </div>
                    <div className="column has-text-centered">
                      <Radio id="canStopN" value="no" className="radio" />
                    </div>
                  </div>
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>
                I acknowledge that the Contribution will be used in connection with the research activities of the University of Edinburgh, which may include the construction of speech synthesised voices.
              </td>
              <td colSpan="2">
                <RadioGroup
                  name="happy" id="happy"
                  selectedValue={happy}
                  onChange={val => {
                    setHappy(val);
                    // setAllYes();
                  }}
                >
                  <div className="columns">
                    <div className="column has-text-centered">
                      <Radio id="happyY" value="yes" className="radio" />
                    </div>
                    <div className="column has-text-centered">
                      <Radio id="happyN" value="no" className="radio" />
                    </div>
                  </div>
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>
                I acknowledge and agree that the University shall be entitled to use my child’s Contribution at the University’s discretion and shall be entitled to commercially licence my child’s Contribution.
              </td>
              <td colSpan="2">
                <RadioGroup
                  name="uniSynth" id="uniSynth"
                  selectedValue={uniSynth}
                  onChange={val => {
                    setUniSynth(val);
                  }}
                >
                  <div className="columns">
                    <div className="column has-text-centered">
                      <Radio id="uniSynthY" value="yes" className="radio" />
                    </div>
                    <div className="column has-text-centered">
                      <Radio id="uniSynthN" value="no" className="radio" />
                    </div>
                  </div>
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>
                The University's rights subsequently may be freely assigned or licensed by the University.
              </td>
              <td colSpan="2">
                <RadioGroup
                  name="uniUse" id="uniUse"
                  selectedValue={uniUse}
                  onChange={val => {
                    setUniUse(val);
                    // setAllYes();
                  }}
                >
                  <div className="columns">
                    <div className="column has-text-centered">
                      <Radio id="uniUseY" value="yes" className="radio" />
                    </div>
                    <div className="column has-text-centered">
                      <Radio id="uniUseN" value="no" className="radio" />
                    </div>
                  </div>
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>
                My child is donating their voice recording and will not receive now, or in the future, any payment for his or her Contribution.
              </td>
              <td colSpan="2">
                <RadioGroup
                  name="noPayment" id="noPayment"
                  selectedValue={noPayment}
                  onChange={val => {
                    setNoPayment(val);
                  }}
                >
                  <div className="columns">
                    <div className="column has-text-centered">
                      <Radio id="noPaymentY" value="yes" className="radio" />
                    </div>
                    <div className="column has-text-centered">
                      <Radio id="noPaymentN" value="no" className="radio" />
                    </div>
                  </div>
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>
                I agree to my child taking part in the above study.
              </td>
              <td colSpan="2">
                <RadioGroup
                  name="agreeTaking" id="agreeTaking"
                  selectedValue={agreeTaking}
                  onChange={val => {
                    setAgreeTaking(val);
                  }}
                >
                  <div className="columns">
                    <div className="column has-text-centered">
                      <Radio id="agreeTakingY" value="yes" className="radio" />
                    </div>
                    <div className="column has-text-centered">
                      <Radio id="agreeTakingN" value="no" className="radio" />
                    </div>
                  </div>
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td colSpan="3" className="has-text-right">
                <button style={{
                  // opacity: allYes ? 1 : 0.4,
                  marginTop: 20
                }}
                  onClick={() => {
                    if (!allYes()) return window.alert("You must answer Yes to the questions to continue.");

                    fetch(`/api/consent/parent`, {
                      method: "POST",
                      headers: {
                        "Authorization": "Bearer " + userId
                      }
                    }).then(r => r.json()).then(data => {
                      console.info(data);
                      return window.document.location.assign("3a");
                    });
                  }}
                >Agree &amp; Proceed</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    {footerSteps(2)}
    <div className="has-text-left is-size-7 is-family-monospace">
      {helloData.participantCode} {helloData.headers ? `${helloData.headers["cloudfront-viewer-city"] || ""} ${helloData.headers["cloudfront-viewer-country-region-name"] || ""}` : null}
    </div>
  </div>;
};

export const Step3UserConsent = _props => {
  const [understood, setUnderstood] = useState(),
    [asked, setAsked] = useState(),
    [answered, setAnswered] = useState(),
    [told, setTold] = useState(),
    [canStop, setCanStop] = useState(),
    [happy, setHappy] = useState(),
    [helloData, setHelloData] = useState({}),
    yes = "yes",
    allYes = () => !!(
      understood === yes && asked === yes && answered === yes &&
      told === yes && canStop === yes && happy === yes
    ),
    userId = localStorage.getItem("i");

  useEffect(() => {
    loadHelloData(userId, setHelloData)
  }, []);

  if (!userId) window.document.location.assign("/");

  return <div>
    <div className="columns">
      <div className="column is-10 is-offset-1">
        <h3 className="title is-3">
          Registration
        </h3>
        We also want to make sure that you are happy to take part in the project.
        <br /><br />
        <table className="table table-condensed">
          <thead>
            <tr>
              <th><br /></th>
              <th>Yes</th>
              <th>No</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>I have read and understand the information about the project</td>
              <td colSpan="2">
                <RadioGroup
                  name="asked" id="asked"
                  selectedValue={asked}
                  onChange={val => {
                    setAsked(val);
                  }}
                >
                  <div className="columns">
                    <div className="column has-text-centered">
                      <Radio id="askedY" value="yes" className="radio" />
                    </div>
                    <div className="column has-text-centered">
                      <Radio id="askedN" value="no" className="radio" />
                    </div>
                  </div>
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>
                I have asked all the questions about the project that I want to
              </td>
              <td colSpan="2">
                <RadioGroup
                  name="understood" id="understood"
                  selectedValue={understood}
                  onChange={val => {
                    setUnderstood(val);
                  }}
                >
                  <div className="columns">
                    <div className="column has-text-centered">
                      <Radio id="understoodY" value="yes" className="radio" />
                    </div>
                    <div className="column has-text-centered">
                      <Radio id="understoodN" value="no" className="radio" />
                    </div>
                  </div>
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>
                My questions have been answered
              </td>
              <td colSpan="2">
                <RadioGroup
                  name="answered" id="answered"
                  selectedValue={answered}
                  onChange={val => {
                    setAnswered(val);
                  }}
                >
                  <div className="columns">
                    <div className="column has-text-centered">
                      <Radio id="answeredY" value="yes" className="radio" />
                    </div>
                    <div className="column has-text-centered">
                      <Radio id="answeredN" value="no" className="radio" />
                    </div>
                  </div>
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>
                I was told everything I want to know about what I have to do to take part.
              </td>
              <td colSpan="2">
                <RadioGroup
                  name="told" id="told"
                  selectedValue={told}
                  onChange={val => {
                    setTold(val);
                  }}
                >
                  <div className="columns">
                    <div className="column has-text-centered">
                      <Radio id="toldY" value="yes" className="radio" />
                    </div>
                    <div className="column has-text-centered">
                      <Radio id="toldN" value="no" className="radio" />
                    </div>
                  </div>
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>
                I know that I can stop taking part whenever I want, for any reason
              </td>
              <td colSpan="2">
                <RadioGroup
                  name="canStop" id="canStop"
                  selectedValue={canStop}
                  onChange={val => {
                    setCanStop(val);
                  }}
                >
                  <div className="columns">
                    <div className="column has-text-centered">
                      <Radio id="canStopY" value="yes" className="radio" />
                    </div>
                    <div className="column has-text-centered">
                      <Radio id="canStopN" value="no" className="radio" />
                    </div>
                  </div>
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>
                I am happy to take part.
              </td>
              <td colSpan="2">
                <RadioGroup
                  name="happy" id="happy"
                  selectedValue={happy}
                  onChange={val => {
                    setHappy(val);
                  }}
                >
                  <div className="columns">
                    <div className="column has-text-centered">
                      <Radio id="happyY" value="yes" className="radio" />
                    </div>
                    <div className="column has-text-centered">
                      <Radio id="happyN" value="no" className="radio" />
                    </div>
                  </div>
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td colSpan="3" className="has-text-right">
                <button style={{ marginTop: 20 }}
                  onClick={() => {
                    if (!allYes()) return window.alert("You must answer Yes to the questions to continue.");

                    fetch(`/api/consent/child`, {
                      method: "POST",
                      headers: {
                        "Authorization": "Bearer " + userId
                      }
                    }).then(r => r.json()).then(data => {
                      console.info(data);
                      return window.document.location.assign("4");
                    });
                  }}
                >Agree &amp; Proceed</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    {footerSteps(3)}
    <div className="has-text-left is-size-7 is-family-monospace">
      {helloData.participantCode} {helloData.headers ? `${helloData.headers["cloudfront-viewer-city"] || ""} ${helloData.headers["cloudfront-viewer-country-region-name"] || ""}` : null}
    </div>
  </div>;
};
