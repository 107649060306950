import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export const Welcome = _props => {

  const vid3 = <div>
    <iframe src="https://player.vimeo.com/video/643859001?h=3f66cfc225" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
  </div>;

  return <div className="columns">
    <div className="column has-text-centered">
      <h4 className="title is-4" style={{ fontWeight: 400 }}>
        Welcome to the Junior Voice Project<br /><br />
        A collaborative research project between the University of Edinburgh and SpeakUnique.
      </h4>
      <div className="columns">
        <div className="column"></div>
        <div className="column">
          <div id="video" className="is-centered"
            style={{
              height: 360, width: 640,
              minHeight: 300, minWidth: 400
            }}>
            {vid3}
          </div>
        </div>
        <div className="column"></div>
      </div>
      {
        !!localStorage.getItem("i") ?
          <div className="columns">
            <div className="column has-text-centered">
              <button onClick={() => {
                localStorage.removeItem("i");
                window.document.location.reload();
              }}
              >
                <FontAwesomeIcon icon={faArrowLeft}
                  style={{ marginRight: 3 }}
                />
                Logout
              </button>
              <span style={{ marginLeft: 12, marginRight: 12 }}>&nbsp;</span>
              <button onClick={() => window.document.location.assign("/start")}
              >Resume <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </div> :
          <div className="has-text-centered">
            <a href={"1"}>
              <button style={{ width: 230, margin: 10 }} >Get Started <FontAwesomeIcon icon={faArrowRight} /></button>
            </a><br />
            <a href={"/l"}>
              <button style={{ width: 230, margin: 10 }} >Resume a Previous Session</button>
            </a>
          </div>
      }
    </div>
  </div>;
};
