import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";

export const RecordingInstructions = _props => {

  const vid = <iframe src="https://player.vimeo.com/video/643868174?h=8a3521a87a" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>;

  return <div className="columns">
    <div className="column has-text-centered" style={{ paddingTop: 50 }}>
      <div className="columns">
        <div className="column"></div>
        <div className="column has-text-centered">
          <div id="video" className="is-centered has-text-centered"
            style={{
              height: 360, width: 640, minHeight: 300, minWidth: 400
            }}>
            {vid}
          </div>
        </div>
        <div className="column"></div>
      </div>
      <div className="has-text-centered">
        <a href={"/start"}>
          <button style={{ width: 230, margin: 10 }}>
            <FontAwesomeIcon icon={faArrowLeft} /> Resume </button>
        </a>
      </div>
    </div>
  </div>;
};
