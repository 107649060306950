import React, { _useState, _useEffect } from "react";

export const MicrophonePosition = _props => {

  return <div>
    <div className="columns">
      <div className="column is-10 is-offset-1">
        <h3 className="title is-3 has-text-centered">
          Microphone Positioning
        </h3>
        <div className="columns">
          <div className="column has-text-centered"
          >
            <img src="Fatima14.png" /><br/>
            Position your microphone<br/>
            so it is level with your mouth
          </div>
          <div className="column has-text-centered"
          >
            <img src="Fatima12.png" /><br/>
            Not too low...
          </div>
          <div className="column has-text-centered"
          >
            <img src="Fatima13.png" /><br/>
            ...and not too high
          </div>
        </div>

        <div className="has-text-right">
          <a href="/j/t1char">
            <button style={{ marginRight: 80 }}>
              Next
          </button>
          </a>
        </div>
      </div>
    </div>
  </div>;
};
