import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/pro-regular-svg-icons";

export const RegisteredWelcome = _props => // one-time, thanks for registering
  <div>
    <div className="columns">
      <div className="column is-8 is-offset-2">
        <h3 className="title is-3 has-text-centered"
          style={{ marginBottom: 38 }}
        >
          Registration Complete!
        </h3>
        Thank you for registering for an account to take part in the Junior Voice Project.<br /><br />
        If you need to access your account again, you can log in with your Unique Participant Code and the password you set at registration.<br /><br />

        <div className="has-text-centered">
          <button style={{
            marginTop: 20
          }}
            onClick={() => {
              return window.document.location.assign("start");
            }}
          >Get Started</button>
        </div>
      </div>
    </div>
  </div>;

export const AllDone = _props => // one-time, thanks you all done
  <div>
    <div className="columns">
      <div className="column is-8 is-offset-2 has-text-centered">
        <h3 className="title is-3 has-text-centered"
          style={{ marginBottom: 38 }}
        >
          Finished! <FontAwesomeIcon icon={faCheckSquare} size="4x" />
        </h3>
        Thank you for taking part in the Junior Voice Project.<br /><br />

        <div className="has-text-centered">
          <button style={{
            marginTop: 20
          }}
            onClick={() => {
              window.document.location.assign("/start");
            }}
          >Review Your Work</button>
        </div>  

            OR

        <div className="has-text-centered">
          <button style={{
            marginTop: 20
          }}
            onClick={() => {
              localStorage.removeItem("i");
              window.document.location.assign("/");
            }}
          >Finish &amp; Logout</button>
        </div>
      </div>
    </div>
  </div>;

